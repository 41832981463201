<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import Tab1Require from '@/views/pages/require/tab1.vue';
import Tab2Require from '@/views/pages/require/tab2.vue';
import Tab3Require from '@/views/pages/require/tab3.vue';
import Tab4Require from '@/views/pages/require/tab4.vue';
import Tab5Require from '@/views/pages/require/tab5.vue';
import Tab6Require from '@/views/pages/require/tab6.vue';
import Tab7Require from '@/views/pages/require/tab7.vue';
import Tab8Require from '@/views/pages/require/tab8.vue';
import Tab9Require from '@/views/pages/require/tab9.vue';
import Tab10Require from '@/views/pages/require/tab10.vue';
import { authComputed, masterMethods } from '@/state/helpers';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: ' パートナー依頼シート',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Tab10Require,
        Tab9Require,
        Tab8Require,
        Tab7Require,
        Tab6Require,
        Tab5Require,
        Tab4Require,
        Tab3Require,
        Tab2Require,
        Tab1Require,
        Layout,
        PageHeader,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            checkUpdate: false,
            title: ' パートナー依頼シート',
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: ' パートナー依頼シート',
                    active: true
                }
            ],
            loading: false,
            checkOrderTab8: false,
            detailBeginForDocument: {},
            check1: false,
            check2: false,
            check3: false,
            check4: false,
            largeItem: [],
            smallItem: [],
            data: {
                campaign_id: this.$route.query.id,
                information_management: {
                    storage_site: '',
                    file_name: '',
                    web_management_screen: '',
                    social_media_account: '',
                    cr_supporter: ''
                },
                request_parameter_web: {
                    registration_form: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    application_form_id_asia_only: {
                        data: '（例）0000　※エイジアのフォーム一覧のIDを記載してください。',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    form_name: {
                        data: '（例）●●●●●●キャンペーン応募フォーム',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'メールドメイン(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: '※「テスト・test」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_line: {
                    registration_form: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_in_cr: {
                        data: '（例）0000　※CR内のキャンペーン名を記載してください。',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    date_end_time_field: {
                        data: '情報更新日時／応募最終日時',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application: {
                        data: '「テスト」「test」「パルディア」「paldia」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_postcard: {
                    address: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    sorting_method: {
                        data: '手仕分け',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_expiration_date: {
                        data: '※消印有効期限：●月●日消印有効',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_twitter_rt: {
                    overview_campaign: [],
                    sns: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    collection_tools: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_atelu_only: {
                        data: '（例）●●キャンペーン　※ATELUのキャンペーン一覧タイトルを記載',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_twitter_rt: {
                        data: '引用RTは応募対象に 含む／含まない',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_to_be_collected_word: {
                        data: 'RT⇒（対象文言）以外除外',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_to_be_collected_url: {
                        data: '引用RT⇒（対象URL）以外除外',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'キャンペーン実施公式アカウント（＠●●）を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: 'アカウント(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_3: {
                        data: '※「＠nohinpaldia」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_detail_direction: {
                        data: '投稿文のRT数と応募報告数に差異がないか確認！',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_twitter_instagram: {
                    overview_campaign: [],
                    sns: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    collection_tools: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_atelu_only: {
                        data: '（例）●●キャンペーン　※ATELUのキャンペーン一覧タイトルを記載',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    tag_collected: {
                        data: '＃●●（AND／OR）＃●●',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'キャンペーン実施公式アカウント（＠●●）を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: 'アカウント(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_3: {
                        data: '※「テスト・test」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_campaign_open: {
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_lottery: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_postcard: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    winning_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    winning_condition_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_3: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_quiz_answer_details: {
                        data: 'クイズの答え「」',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_survey_conditions_etc: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '空欄',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_campaign_close: {
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_lottery: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_postcard: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_3: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_quiz_answer_details: {
                        data: 'クイズの答え「」',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_survey_conditions_etc: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '空欄',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_date: {
                        data: {
                            start_date: '',
                            end_date: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    shipping: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_price: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_store_or_distribution: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_instagram: {
                    hashtags: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    excluded_accounts: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check_1: {
                        data: 'あり（目視チェック）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check_2: {
                        data: 'DM配信時の対応でOK',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_followers: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_follower_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_posts: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_post_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_likes: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_like_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    required_words_in_the_post: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_word_count: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlapping_conditions_account_id: {
                        data: '完全一致',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_items_to_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    account_name: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_keywords: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    profile_statement: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    presence_of_specified_keyword: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_prize_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file_status_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_extracted: {
                        data: {
                            start_date: '',
                            end_date: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions_status_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_items: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching_data: {
                        data: {
                            text_1: '＜あり(フォーム照合機能なしの場合＞',
                            text_2: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_preson_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_others: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_twitter: {
                    hashtags: {
                        data: [],
                        sum_total_winner: 0
                    },
                    twitter_rt: {
                        data: [
                            {
                                name: 'hashtag1',
                                preliminary_winner: 0,
                                number_of_win: 0,
                                total_winner: 0,
                                first_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                },
                                secondary_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                }
                            },
                            {
                                name: 'hashtag1',
                                preliminary_winner: 0,
                                number_of_win: 0,
                                total_winner: 0,
                                first_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                },
                                secondary_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                }
                            }
                        ],
                        //sum of total_winner = 22 + 23
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    excluded_accounts: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_followers: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_follower_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_posts: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_post_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    required_words_in_the_post: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_word_count: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlapping_conditions_account_id: {
                        data: '完全一致',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_items_to_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    account_name: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_keywords: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    profile_statement: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    presence_of_specified_keyword: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_prize_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file_status_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_extracted: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions_status_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_items: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching_data: {
                        data: {
                            text_1: '＜あり(フォーム照合機能なしの場合＞',
                            text_2: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_preson_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_others: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_line: {
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    lottery_method_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_id_record: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_title: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    application_validity_period: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_winning: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_application_form: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_type: [],
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_type: [],
                    same_person_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate: [],
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_error_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_validity_period: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    shipping: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_store_or_distribution: {
                        data: {
                            select_data: [],
                            input_data: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                }
            },
            data11: {
                campaign_id: this.$route.query.id,
                information_management: {
                    storage_site: '',
                    file_name: '',
                    web_management_screen: '',
                    social_media_account: '',
                    cr_supporter: ''
                },
                request_parameter_web: {
                    registration_form: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    application_form_id_asia_only: {
                        data: '（例）0000　※エイジアのフォーム一覧のIDを記載してください。',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    form_name: {
                        data: '（例）●●●●●●キャンペーン応募フォーム',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'メールドメイン(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: '※「テスト・test」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_line: {
                    registration_form: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_in_cr: {
                        data: '（例）0000　※CR内のキャンペーン名を記載してください。',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    date_end_time_field: {
                        data: '情報更新日時／応募最終日時',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application: {
                        data: '「テスト」「test」「パルディア」「paldia」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_postcard: {
                    address: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    sorting_method: {
                        data: '手仕分け',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_expiration_date: {
                        data: '※消印有効期限：●月●日消印有効',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_twitter_rt: {
                    overview_campaign: [],
                    sns: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    collection_tools: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_atelu_only: {
                        data: '（例）●●キャンペーン　※ATELUのキャンペーン一覧タイトルを記載',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },

                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_twitter_rt: {
                        data: '引用RTは応募対象に 含む／含まない',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_to_be_collected_word: {
                        data: 'RT⇒（対象文言）以外除外',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_to_be_collected_url: {
                        data: '引用RT⇒（対象URL）以外除外',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'キャンペーン実施公式アカウント（＠●●）を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: 'アカウント(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_3: {
                        data: '※「＠nohinpaldia」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_detail_direction: {
                        data: '投稿文のRT数と応募報告数に差異がないか確認！',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                request_parameter_twitter_instagram: {
                    overview_campaign: [],
                    sns: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    collection_tools: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_name_atelu_only: {
                        data: '（例）●●キャンペーン　※ATELUのキャンペーン一覧タイトルを記載',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    tag_collected: {
                        data: '＃●●（AND／OR）＃●●',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    reporting_schedule_detail: {
                        data: '※詳細はスケジュールをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    contents_of_report_detail: {
                        data: '※詳細は通数フォーマットをご確認ください',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_1: {
                        data: 'キャンペーン実施公式アカウント（＠●●）を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_2: {
                        data: 'アカウント(＠●●)を除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    test_application_3: {
                        data: '※「テスト・test」「＠paldia.co.jp」は除く',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    invalid_content: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_information: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_campaign_open: {
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_lottery: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_postcard: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    winning_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    winning_condition_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_3: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_quiz_answer_details: {
                        data: 'クイズの答え「」',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_survey_conditions_etc: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '空欄',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_campaign_close: {
                    unique_id_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    unique_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_lottery: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_postcard: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution_overlap: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_3: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_quiz_answer_details: {
                        data: 'クイズの答え「」',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_survey_conditions_etc: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_with_other_data_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '空欄',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_date: {
                        data: {
                            start_date: '',
                            end_date: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    shipping: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_price: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_store_or_distribution: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_instagram: {
                    hashtags: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    excluded_accounts: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check_1: {
                        data: 'あり（目視チェック）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check_2: {
                        data: 'DM配信時の対応でOK',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_followers: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_follower_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_posts: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_post_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_likes: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_like_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    required_words_in_the_post: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_word_count: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlapping_conditions_account_id: {
                        data: '完全一致',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_items_to_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    account_name: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_keywords: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    profile_statement: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    presence_of_specified_keyword: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_prize_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file_status_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_extracted: {
                        data: {
                            start_date: '',
                            end_date: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions_status_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_items: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching_data: {
                        data: {
                            text_1: '＜あり(フォーム照合機能なしの場合＞',
                            text_2: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_preson_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_others: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_twitter: {
                    hashtags: {
                        data: [],
                        sum_total_winner: 0
                    },
                    twitter_rt: {
                        data: [
                            {
                                name: 'hashtag1',
                                preliminary_winner: 0,
                                number_of_win: 0,
                                total_winner: 0,
                                first_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                },
                                secondary_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                }
                            },
                            {
                                name: 'hashtag1',
                                preliminary_winner: 0,
                                number_of_win: 0,
                                total_winner: 0,
                                first_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                },
                                secondary_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                }
                            }
                        ],
                        //sum of total_winner = 22 + 23
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    excluded_accounts: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    official_account_follow_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_followers: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_follower_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_posts: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_post_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    required_words_in_the_post: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    post_word_count: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_condition_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    number_of_confirmed_cases_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlapping_conditions_account_id: {
                        data: '完全一致',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_items_to_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    account_name: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specified_keywords: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    profile_statement: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    presence_of_specified_keyword: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_2: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    check_the_latest_posts_3: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_prize_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    target_file_status_check: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    period_conditions_extracted: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    specifies_the_data_format_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions_status_check: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    matching_items: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    condition_maching_data: {
                        data: {
                            text_1: '＜あり(フォーム照合機能なしの場合＞',
                            text_2: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_id_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_house_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_person_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_same_preson_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    overlap_condition_others: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '番地有無',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '桁数不足',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: 'メールアドレスの形をしているか（@があり、半角英数）',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                },
                lottery_line: {
                    prizes: {
                        data: [],
                        sum_total_winner: 0
                    },
                    lottery_method: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    lottery_method_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_id_record: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    campaign_title: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    application_validity_period: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    prize_winning: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_application_form: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    cross_distribution: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    ratio_specification_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_conditions: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_condition_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_type: [],
                    same_household_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_household_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_type: [],
                    same_person_1: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    same_person_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate: [],
                    others_duplicate_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    others_duplicate_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_error_name: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_1: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    address_verification_2: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_phone_number_is_incomplete: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    confirm_email_address_is_incomplete: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_errors: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    other_error_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_validity_period: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    shipping: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_conditions: {
                        data: '',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_store_or_distribution: {
                        data: {
                            select_data: [],
                            input_data: ''
                        },
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other: {
                        data: [],
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    },
                    purchase_info_other_detail: {
                        data: '＜ありの場合詳細記入＞',
                        first_check: {
                            id: '',
                            name: '',
                            change: true
                        },
                        secondary_check: {
                            id: '',
                            name: '',
                            change: true
                        }
                    }
                }
            }
        };
    },
    watch: {
        'data.lottery_campaign_open.period_conditions_postcard.data': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_campaign_open.period_conditions_postcard.data_y = date[0];
                this.data.lottery_campaign_open.period_conditions_postcard.data_m = date[1];
                this.data.lottery_campaign_open.period_conditions_postcard.data_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_campaign_close.period_conditions_postcard.data': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_campaign_close.period_conditions_postcard.data_y = date[0];
                this.data.lottery_campaign_close.period_conditions_postcard.data_m = date[1];
                this.data.lottery_campaign_close.period_conditions_postcard.data_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_line.purchase_validity_period.data.start_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_line.purchase_validity_period.data.start_date_y = date[0];
                this.data.lottery_line.purchase_validity_period.data.start_date_m = date[1];
                this.data.lottery_line.purchase_validity_period.data.start_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_line.purchase_validity_period.data.end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_line.purchase_validity_period.data.end_date_y = date[0];
                this.data.lottery_line.purchase_validity_period.data.end_date_m = date[1];
                this.data.lottery_line.purchase_validity_period.data.end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_campaign_close.purchase_date.data.start_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_campaign_close.purchase_date.data.start_date_y = date[0];
                this.data.lottery_campaign_close.purchase_date.data.start_date_m = date[1];
                this.data.lottery_campaign_close.purchase_date.data.start_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_campaign_close.purchase_date.data.end_date': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.data.lottery_campaign_close.purchase_date.data.end_date_y = date[0];
                this.data.lottery_campaign_close.purchase_date.data.end_date_m = date[1];
                this.data.lottery_campaign_close.purchase_date.data.end_date_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'data.lottery_campaign_open.prizes.data': {
            handler() {
                console.log(22);
                this.calculateTotals('lottery_campaign_open', 'prizes');
            },
            deep: true
        },
        'data.lottery_campaign_close.prizes.data': {
            handler() {
                console.log(22);
                this.calculateTotals('lottery_campaign_close', 'prizes');
            },
            deep: true
        },
        'data.lottery_line.prizes.data': {
            handler() {
                console.log(22);
                this.calculateTotals('lottery_line', 'prizes');
            },
            deep: true
        },
        'data.lottery_twitter.hashtags.data': {
            handler() {
                console.log(22);
                this.calculateTotals1('lottery_twitter', 'hashtags');
            },
            deep: true
        },
        'data.lottery_twitter.twitter_rt.data': {
            handler() {
                console.log(22);
                this.calculateTotals2('lottery_twitter', 'twitter_rt');
            },
            deep: true
        },
        'data.lottery_instagram.hashtags.data': {
            handler() {
                console.log(22);
                this.calculateTotals1('lottery_instagram', 'hashtags');
            },
            deep: true
        }
        // "data.lottery_campaign_open.prizes.data": {
        //     handler() {
        //         this.calculateTotals('lottery_campaign_open', 'prizes');
        //     },
        //     deep: true
        // }
    },
    mounted() {
        if (this.$route.query.id) {
            this.getListMasterCampaignDetail();
        }
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...masterMethods,
        calculateTotals(k1, k2) {
            this.data[k1][k2].sum_total_winner = 0;
            if (this.data[k1][k2].data)
                this.data[k1][k2].data.forEach((item) => {
                    item.total_winner = parseInt(item.preliminary_winner) + parseInt(item.number_of_win_prize);
                    this.data[k1][k2].sum_total_winner = parseInt(this.data[k1][k2].sum_total_winner) + parseInt(item.total_winner);
                });
        },
        calculateTotals1(k1, k2) {
            this.data[k1][k2].sum_total_winner = 0;
            if (this.data[k1][k2].data)
                this.data[k1][k2].data.forEach((item) => {
                    item.total_winner = parseInt(item.preliminary_winner) + parseInt(item.number_of_win);
                    this.data[k1][k2].sum_total_winner = parseInt(this.data[k1][k2].sum_total_winner) + parseInt(item.total_winner);
                });
        },
        calculateTotals2(k1, k2) {
            this.data[k1][k2].sum_total_winner = 0;
            if (this.data[k1][k2].data)
                this.data[k1][k2].data.forEach((item) => {
                    item.total_winner = parseInt(item.preliminary_winner) + parseInt(item.number_of_win);
                    this.data[k1][k2].sum_total_winner = parseInt(this.data[k1][k2].sum_total_winner) + parseInt(item.total_winner);
                });
        },
        getListMasterCampaignDetail() {
            this.loading = true;
            this.getDetailDocuments({ id: this.$route.query.id }).then((data) => {
                if (data && data.code == 200) {
                    this.detailBeginForDocument = data.data;
                    let hashtags = [];
                    this.detailBeginForDocument.event_prizes &&
                        this.detailBeginForDocument.event_prizes.forEach((item) => {
                            item.preliminary_winner = 0;
                            item.name = item.title;
                            item.first_check = {
                                id: '',
                                name: '',
                                change: true
                            };
                            item.secondary_check = {
                                id: '',
                                name: '',
                                change: true
                            };
                            if (!item.number_of_win_prize) item.number_of_win_prize = 0;

                            hashtags.push({
                                name: item.title,
                                preliminary_winner: 0,
                                number_of_win: item.number_of_win_prize ? item.number_of_win_prize : 0,
                                total_winner: 0,
                                first_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                },
                                secondary_check: {
                                    id: '',
                                    name: '',
                                    change: true
                                }
                            });
                        });
                    //
                    // this.detailBeginForDocument.hashtag.forEach((item) => {
                    //     hashtags.push({
                    //         name: item,
                    //         preliminary_winner: 0,
                    //         number_of_win: 0,
                    //         total_winner: 0,
                    //         first_check: {
                    //             id: '',
                    //             name: '',
                    //             change: true
                    //         },
                    //         secondary_check: {
                    //             id: '',
                    //             name: '',
                    //             change: true
                    //         }
                    //     });
                    // });
                    let sns = [];
                    // let snsData = [];
                    // this.detailBeginForDocument.categories.forEach((item) => {
                    //     if (item.category.value == 'Twitter') {
                    //         sns.push('Twitter');
                    //         // this.data.request_parameter_twitter_rt.sns.data = 'Twitter';
                    //     }
                    //     if (item.category.value == 'LINE') {
                    //         // this.data.request_parameter_line.registration_form.data = 'LINE';
                    //     }
                    //     if (item.category.value == 'Instagram') {
                    //         sns.push('Instagram');
                    //     }
                    //     snsData.push(item.category.value);
                    // });
                    let large_item = [];
                    let small_item = [];
                    let addressData = [];
                    let reporting_schedule_twitter_rt = [];
                    let winning_conditions = [];
                    let lottery_method = [];
                    let lottery_method_twitter = [];
                    let lottery_method_instagram = [];
                    let reporting_schedule_postcard = [];
                    let tool_form = [];
                    this.detailBeginForDocument.campaign_master_order_scopes.forEach((item) => {
                        item.medium_items.forEach((sm) => {
                            if (sm.medium_item.value == '発送データ作成') {
                                this.checkOrderTab8 = true;
                            }

                            sm.small_items.forEach((sml) => {
                                large_item.push(sml.small_item.value);
                                if (sm.medium_item.value == '重複確認') {
                                    if (sml.small_item.value == '同一世帯') {
                                        this.check1 = true;
                                    }
                                    if (sml.small_item.value == '同一人物') {
                                        this.check2 = true;
                                    }
                                    if (sml.small_item.value == 'その他') {
                                        this.check3 = true;
                                    }
                                    if (sml.small_item.value == '固有ID') {
                                        this.check4 = true;
                                    }
                                }
                                sml.detail_items.forEach((dt) => {
                                    if (sml.small_item.value == 'SNSツール') {
                                        small_item.push(dt.value);
                                        let overview_campaign = {
                                            sns: {
                                                data: dt.value,
                                                first_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                },
                                                secondary_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                }
                                            },
                                            collection_tools: {
                                                data: [],
                                                first_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                },
                                                secondary_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                }
                                            },
                                            campaign_name_atelu_only: {
                                                data: '',
                                                first_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                },
                                                secondary_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                }
                                            },
                                            tag_collected: {
                                                data: '＃●●（AND／OR）＃●●',
                                                first_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                },
                                                secondary_check: {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                }
                                            }
                                        };
                                        let collection_tools = [];
                                        this.detailBeginForDocument.campaign_master_order_scope_partner.forEach((t) => {
                                            if (t.master_order_scope && t.master_order_scope.id == dt.id) {
                                                if (t.partner.name == 'コムニコ' && t.tool.tool == 'ATELU') {
                                                    overview_campaign.campaign_name_atelu_only.data = this.detailBeginForDocument.name;
                                                }
                                                collection_tools.push(t.tool.tool);
                                                overview_campaign.collection_tools.data = collection_tools.join('／');
                                            }
                                            this.detailBeginForDocument.categories.forEach((c) => {
                                                console.log(dt.value);
                                                if (c.category && c.category.value == dt.value) {
                                                    overview_campaign.tag_collected.data = c.hashtag
                                                        ? c.hashtag.map((tag) => `#${tag}`).join('／')
                                                        : '';
                                                }
                                            });
                                        });
                                        this.data.request_parameter_twitter_rt.overview_campaign.push(overview_campaign);
                                        this.data.request_parameter_twitter_instagram.overview_campaign.push(overview_campaign);
                                    }
                                    if (
                                        sml.small_item.value == 'はがき回収' &&
                                        (dt.value == '直住所' || dt.value == '私書箱' || dt.value == 'その他')
                                    ) {
                                        addressData.push(dt.value);
                                    }
                                    if (
                                        sml.small_item.value == '応募数報告' &&
                                        (dt.value == '週報' || dt.value == '月報' || dt.value == '最終一括' || dt.value == 'その他')
                                    ) {
                                        reporting_schedule_twitter_rt.push(dt.value);
                                    }
                                    if (
                                        sml.small_item.value == '応募数報告' &&
                                        (dt.value == '週報' || dt.value == '月報' || dt.value == '最終一括' || dt.value == 'その他')
                                    ) {
                                        reporting_schedule_postcard.push(dt.value);
                                    }
                                    if (
                                        dt.value == '引き抜き抽選' ||
                                        dt.value == 'その他' ||
                                        dt.value == 'ランダム抽選' ||
                                        dt.value == '条件付き抽選' ||
                                        dt.value == 'その他'
                                    ) {
                                        winning_conditions.push(dt.value);
                                    }
                                    if (
                                        dt.value == '引き抜き抽選' ||
                                        dt.value == 'その他' ||
                                        dt.value == 'ランダム抽選' ||
                                        dt.value == '条件付き抽選' ||
                                        dt.value == 'その他'
                                    ) {
                                        lottery_method.push(dt.value);
                                    }
                                    if (
                                        sm.medium_item.value == '抽選' &&
                                        (dt.value == '引き抜き抽選' ||
                                            dt.value == 'その他' ||
                                            dt.value == 'ランダム抽選' ||
                                            dt.value == '条件付き抽選' ||
                                            dt.value == 'その他')
                                    ) {
                                        lottery_method_twitter.push(dt.value);
                                    }
                                    if (
                                        sm.medium_item.value == '抽選' &&
                                        (dt.value == '引き抜き抽選' ||
                                            dt.value == 'その他' ||
                                            dt.value == 'ランダム抽選' ||
                                            dt.value == '条件付き抽選' ||
                                            dt.value == 'その他')
                                    ) {
                                        lottery_method_instagram.push(dt.value);
                                    }
                                });
                            });
                        });
                    });
                    this.largeItem = large_item;
                    this.smallItem = small_item;
                    let tool = [];
                    this.detailBeginForDocument.campaign_master_order_scope_partner.forEach((item) => {
                        if (item.tool) tool.push(item.tool.tool);
                        if (
                            item.master_order_scope &&
                            item.tool &&
                            item.master_order_scope.value == 'PL指定ドメイン' &&
                            item.master_order_scope.id == 21 &&
                            item.master_order_scope.key == 'detail_item'
                        )
                            tool_form.push(item.tool.tool);
                    });
                    console.log('321312', this.data.request_parameter_twitter_rt);
                    this.data.lottery_line.purchase_validity_period.data = this.detailBeginForDocument.receipt_expiration_date_formated;
                    console.log(addressData);
                    this.data.request_parameter_postcard.address.data = addressData.join('／');
                    this.data.request_parameter_postcard.reporting_schedule.data =
                        reporting_schedule_postcard.length > 0 ? reporting_schedule_postcard.join('／') : '';
                    this.data.request_parameter_twitter_instagram.reporting_schedule.data = reporting_schedule_twitter_rt.join('／');
                    this.data.request_parameter_twitter_rt.reporting_schedule.data = reporting_schedule_twitter_rt.join('／');
                    this.data.request_parameter_twitter_instagram.sns.data = small_item.join('／');
                    // this.data.request_parameter_twitter_rt.sns.data = small_item.join('／');
                    // this.data.request_parameter_twitter_rt.collection_tools.data = tool.join('／');
                    this.data.lottery_campaign_open.lottery_method.data = lottery_method.join('／');
                    this.data.request_parameter_web.registration_form.data = tool_form.join('／');
                    console.log(tool_form);
                    this.data.lottery_campaign_open.prizes.data = this.detailBeginForDocument.event_prizes;
                    this.data.lottery_campaign_close.prizes.data = this.detailBeginForDocument.event_prizes;
                    this.data.lottery_campaign_close.lottery_method.data = lottery_method.join('／');
                    this.data.lottery_line.prizes.data = this.detailBeginForDocument.event_prizes;
                    this.data.lottery_line.lottery_method.data = lottery_method.join('／');
                    this.data.request_parameter_twitter_instagram.sns.data = sns.join('／');
                    this.data.lottery_twitter.hashtags.data = hashtags;
                    this.data.lottery_twitter.lottery_method.data = lottery_method_twitter.join('／');
                    this.data.lottery_instagram.hashtags.data = hashtags;
                    this.data.lottery_instagram.lottery_method.data = lottery_method_instagram.join('／');

                    this.data = JSON.parse(JSON.stringify(this.data));
                    console.log('321312', this.data11.request_parameter_twitter_rt);
                    this.getRequest();
                } else {
                    this.loading = false;
                }
            });
        },
        getRequest() {
            this.getPartnerRequestSheet({ campaign_id: this.$route.query.id }).then((data) => {
                if (data && data.code == 200) {
                    this.checkUpdate = true;
                    this.data = data.data ? data.data : {};
                    if (this.data) {
                        for (const [key] of Object.entries(this.data)) {
                            for (const [k] of Object.entries(this.data[key])) {
                                if (this.data[key][k]) {
                                    for (const [l] of Object.entries(this.data[key][k])) {
                                        if ((l == 'first_check' || l == 'secondary_check') && !this.data[key][k][l]) {
                                            this.data[key][k][l] = {
                                                id: '',
                                                name: '',
                                                change: true
                                            };
                                        }
                                    }
                                    if (k == 'prizes') {
                                        this.data[key][k].data.forEach((item, h) => {
                                            if (!item.first_check)
                                                this.data[key][k].data[h].first_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                            if (!item.secondary_check)
                                                this.data[key][k].data[h].secondary_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                        });
                                    }
                                    if (k == 'overview_campaign') {
                                        console.log('222' + this.data[key][k]);
                                        this.data[key][k].forEach((item, h) => {
                                            for (const [l] of Object.entries(item)) {
                                                for (const [z] of Object.entries(this.data[key][k][h][l])) {
                                                    if ((z == 'first_check' || z == 'secondary_check') && !this.data[key][k][h][l][z]) {
                                                        this.data[key][k][h][l][z] = {
                                                            id: '',
                                                            name: '',
                                                            change: true
                                                        };
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    if (k == 'hashtags') {
                                        console.log(key);
                                        console.log(this.data[key][k].data);
                                        this.data[key][k].data.forEach((item, h) => {
                                            if (!item.first_check)
                                                this.data[key][k].data[h].first_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                            if (!item.secondary_check)
                                                this.data[key][k].data[h].secondary_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                        });
                                    }
                                    if (k == 'twitter_rt') {
                                        console.log(key);
                                        console.log(this.data[key][k].data);
                                        this.data[key][k].data.forEach((item, h) => {
                                            if (!item.first_check)
                                                this.data[key][k].data[h].first_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                            if (!item.secondary_check)
                                                this.data[key][k].data[h].secondary_check = {
                                                    id: '',
                                                    name: '',
                                                    change: true
                                                };
                                        });
                                    }
                                }
                                // if (key == 'request_parameter_twitter_instagram') {
                                //     console.log(this.data[key][k]);
                                // }
                            }
                        }
                    }

                    this.data = JSON.parse(JSON.stringify(this.data));
                    console.log(this.data11);
                    this.data = this.mergeObjects(this.data11, this.data);
                }
                this.loading = false;
            });
        },
        mergeObjects(template, data) {
            const result = { ...data };

            for (const key in template) {
                // eslint-disable-next-line no-prototype-builtins
                if (template.hasOwnProperty(key)) {
                    if (typeof template[key] === 'object' && !Array.isArray(template[key])) {
                        // Nếu key là object, gọi đệ quy để merge object con
                        result[key] = this.mergeObjects(template[key], data[key] || {});
                    } else {
                        // Nếu key là giá trị đơn giản, bổ sung nếu thiếu
                        // eslint-disable-next-line no-prototype-builtins
                        if (!data.hasOwnProperty(key) && key != 'change' && key != 'change') {
                            result[key] = template[key];
                        }
                    }
                }
            }

            return result;
        },
        register() {
            if (this.checkUpdate) {
                this.updatePartnerRequestSheet(this.data).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`Your record has been deleted.`, {
                            title: `更新されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                    }
                });
            } else {
                this.partnerRequestSheet(this.data).then((data) => {
                    if (data.code == 200) {
                        this.checkUpdate = true;
                        this.$bvToast.toast(`Your record has been deleted.`, {
                            title: `更新されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                    }
                });
            }
        },
        checkPlatFomTab(label) {
            let check = false;
            if (this.detailBeginForDocument.categories) {
                this.detailBeginForDocument.categories.forEach((item) => {
                    if (label.includes(item.category.value)) {
                        check = true;
                    }
                });
            }
            return check;
        },
        checkPlatFomTabLarge(label) {
            let check = false;
            this.largeItem.forEach((item) => {
                if (label.includes(item)) {
                    check = true;
                }
            });
            return check;
        },
        checkPlatFomTabSmall(label) {
            let check = false;
            this.smallItem.forEach((item) => {
                if (label.includes(item)) {
                    check = true;
                }
            });

            return check;
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="!loading">
            <div class="col-lg-12 check-text-danger">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">パートナー依頼シート</h3>
                    </div>
                    <div class="card-body box8 form--sroll1" style="height: 600px; overflow-y: auto">
                        <b-tabs vertical justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                            <!--                  【管理画面情報】-->
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="">【管理画面情報】</span>
                                </template>
                                <div>
                                    <div class="table-responsive">
                                        <table class="table-bordered table">
                                            <tbody>
                                                <tr>
                                                    <th class="text-center bg-color7 font-weight-bold" colspan="2">格納場所</th>
                                                </tr>
                                                <tr>
                                                    <td>格納場所</td>
                                                    <td>
                                                        <input type="text" class="form-control" v-model="data.information_management.storage_site" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>ファイル名</td>
                                                    <td>
                                                        <input type="text" class="form-control" v-model="data.information_management.file_name" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colspan="2" class="text-danger">※格納場所記載の場合以下削除</th>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class="text-center bg-color8 font-weight-bold">WEB管理画面</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <textarea v-model="data.information_management.web_management_screen" class="form-control" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class="text-center bg-color8 font-weight-bold">SNSアカウント</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <textarea v-model="data.information_management.social_media_account" class="form-control" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class="text-center bg-color8 font-weight-bold">CRサポーター</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <textarea v-model="data.information_management.cr_supporter" class="form-control" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-tab>
                            <!--                  【通数依頼】はがき-->
                            <b-tab class="border-0" v-if="checkPlatFomTabLarge(['はがき回収']) && checkPlatFomTab(['ハガキ'])">
                                <template v-slot:title>
                                    <span class="">【通数依頼】はがき</span>
                                </template>
                                <div>
                                    <Tab1Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.request_parameter_postcard"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【通数依頼】WEB-->
                            <b-tab class="border-0" v-if="checkPlatFomTabLarge(['WEBフォーム']) && checkPlatFomTab(['WEB'])">
                                <template v-slot:title>
                                    <span class="">【通数依頼】WEB</span>
                                </template>
                                <div>
                                    <Tab2Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.request_parameter_web"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【通数依頼】Twitter・Instagram＃-->
                            <b-tab
                                class="border-0"
                                v-if="checkPlatFomTabSmall(['Twitter', 'Instagram']) && checkPlatFomTab(['Twitter', 'Instagram'])"
                            >
                                <template v-slot:title>
                                    <span class="">【通数依頼】Twitter・Instagram＃</span>
                                </template>
                                <div>
                                    <Tab3Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.request_parameter_twitter_instagram"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【通数依頼】TwitterRT-->
                            <b-tab
                                class="border-0"
                                v-if="checkPlatFomTabSmall(['Twitter', 'Instagram']) && checkPlatFomTab(['Twitter', 'Instagram'])"
                            >
                                <template v-slot:title>
                                    <span class="">【通数依頼】TwitterRT</span>
                                </template>
                                <div>
                                    <Tab4Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.request_parameter_twitter_rt"
                                    />
                                </div>
                            </b-tab>

                            <!--              【通数依頼】CR-->
                            <b-tab class="border-0" v-if="checkPlatFomTabSmall('LINE') && checkPlatFomTab('LINE')">
                                <template v-slot:title>
                                    <span class="">【通数依頼】CR</span>
                                </template>
                                <div>
                                    <Tab5Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.request_parameter_line"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【抽選】オープンCP-->
                            <b-tab
                                class="border-0"
                                v-if="detailBeginForDocument.campaign_type && detailBeginForDocument.campaign_type.value == 'オープン'"
                            >
                                <template v-slot:title>
                                    <span class="">【抽選】オープンCP賞</span>
                                </template>
                                <div>
                                    <Tab6Require
                                        :check1="check1"
                                        :check2="check2"
                                        :check3="check3"
                                        :check4="check4"
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.lottery_campaign_open"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【抽選】クローズCP-->
                            <b-tab
                                class="border-0"
                                v-if="detailBeginForDocument.campaign_type && detailBeginForDocument.campaign_type.value == 'クローズ'"
                            >
                                <template v-slot:title>
                                    <span class="">【抽選】クローズCP</span>
                                </template>
                                <div>
                                    <Tab7Require
                                        :check1="check1"
                                        :check2="check2"
                                        :check3="check3"
                                        :check4="check4"
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.lottery_campaign_close"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【抽選】Twitter-->
                            <b-tab class="border-0" v-if="checkPlatFomTab(['Twitter'])">
                                <template v-slot:title>
                                    <span class="">【抽選】Twitter</span>
                                </template>
                                <div>
                                    <Tab8Require
                                        :check-order="checkOrderTab8"
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.lottery_twitter"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【抽選】Instagram-->
                            <b-tab class="border-0" v-if="checkPlatFomTab(['Instagram'])">
                                <template v-slot:title>
                                    <span class="">【抽選】Instagram</span>
                                </template>
                                <div>
                                    <Tab9Require
                                        :check-order="checkOrderTab8"
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.lottery_instagram"
                                    />
                                </div>
                            </b-tab>

                            <!--                  【抽選】CR-->
                            <b-tab class="border-0" v-if="checkPlatFomTab(['LINE'])">
                                <template v-slot:title>
                                    <span class="">【抽選】CR</span>
                                </template>
                                <div>
                                    <Tab10Require
                                        :detail-begin-for-document="detailBeginForDocument"
                                        :current-user="currentUser"
                                        :data-object.sync="data.lottery_line"
                                    />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light"
                            @click="
                                () => {
                                    $router.push({
                                        path: `/documents`
                                    });
                                }
                            "
                        >
                            キャンセル
                        </button>
                        <button type="button" @click="register()" class="btn btn-primary ml-3">保存</button>
                    </div>
                </div>
            </Footer>
        </div>

        <div v-else>
            <div class="d-flex justify-content-center">
                <LoadingIcon />
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}

th[colspan='7'],
th[colspan='8'],
th[colspan='4'] {
    font-size: 20px;
    font-weight: bold;
}
</style>
