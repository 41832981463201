<template>
    <div class="table-responsive">
        <table class="table-bordered table">
            <tbody>
                <tr>
                    <th colspan="8">＜CP概要（クローズCP）＞</th>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン名</td>
                    <td colspan="7">{{ detailBeginForDocument.name }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">クライアント/代理店名</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.clients_formatted
                        }}{{ detailBeginForDocument.agencies_formatted ? '/' + detailBeginForDocument.agencies_formatted : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン期間</td>
                    <td colspan="7">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date)
                        }}{{ detailBeginForDocument.campaign_end_date ? '～' + renderDate1(detailBeginForDocument.campaign_end_date) : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">CPサイト・CPツイート</td>
                    <td colspan="7">{{ detailBeginForDocument.url }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">ディレクション担当</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.person_in_charge ? detailBeginForDocument.person_in_charge.name : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">営業担当</td>
                    <td colspan="7">{{ detailBeginForDocument.sale_staff ? detailBeginForDocument.sale_staff.name : '' }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>

                <tr>
                    <th colspan="8">＜抽選条件＞※応募割れの場合は抽選条件が変わる可能性があります。</th>
                </tr>
                <template v-if="dataObject.prizes.data && dataObject.prizes.data.length > 0">
                    <tr>
                        <th class="bg-color5" :rowspan="dataObject.prizes.data.length + 1">当選人数</th>
                        <th class="bg-color5"></th>
                        <th class="bg-color5">当選者</th>
                        <th class="bg-color5">予備当選者</th>
                        <th class="bg-color5" colspan="2">計</th>
                        <th>1次チェック</th>
                        <th>2次チェック</th>
                    </tr>
                    <tr v-for="(item, k) in dataObject.prizes.data" :key="item.name">
                        <td class="bg-color5">{{ item.name }}賞</td>
                        <td>
                            <input type="number" v-model="dataObject.prizes.data[k].number_of_win_prize" class="form-control" />
                        </td>
                        <td>
                            <input type="number" v-model="dataObject.prizes.data[k].preliminary_winner" class="form-control" />
                        </td>
                        <td>
                            {{ item.total_winner }}
                        </td>
                        <td v-if="k == 0" :rowspan="dataObject.prizes.data.length" align="middle" valign="middle">
                            {{ dataObject.prizes.sum_total_winner }}
                        </td>
                        <td>
                            <label for="" v-if="item.first_check && item.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, k, 'first_check')"
                                />
                                <span v-if="item.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.first_check.id"
                                    @click="
                                        item.first_check = { id: '' };
                                        item.first_check.change = true;
                                    "
                                />
                                <span v-if="item.first_check.id" class="ml-1">{{ item.first_check.name ? item.first_check.name : '' }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="item.secondary_check && item.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="item.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox1($event, currentUser.id, k, 'secondary_check')"
                                />
                                <span v-if="item.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="item.secondary_check.id"
                                    @click="
                                        item.secondary_check = { id: '' };
                                        item.secondary_check.change = true;
                                    "
                                />
                                <span v-if="item.secondary_check.id" class="ml-1">{{
                                    item.secondary_check.name ? item.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <tr>
                    <td colspan="2" class="bg-color5">抽選方法</td>
                    <td colspan="4">
                        {{ dataObject.lottery_method.data }}
                        <!--                        <multiselect-->
                        <!--                            :multiple="true"-->
                        <!--                            :showPointer="false"-->
                        <!--                            :showNoResults="false"-->
                        <!--                            selectedLabel=""-->
                        <!--                            deselectLabel=""-->
                        <!--                            placeholder=""-->
                        <!--                            selectLabel=""-->
                        <!--                            :showNoOptions="false"-->
                        <!--                            v-model="dataObject.lottery_method.data"-->
                        <!--                            :taggable="true"-->
                        <!--                            :options="option.lottery_method"-->
                        <!--                            @tag="addTag($event, 'lottery_method')"-->
                        <!--                        ></multiselect>-->
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.first_check && dataObject.lottery_method.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'first_check')"
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id"
                                @click="
                                    dataObject.lottery_method.first_check = { id: '' };
                                    dataObject.lottery_method.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{
                                dataObject.lottery_method.first_check.name ? dataObject.lottery_method.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.secondary_check && dataObject.lottery_method.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'secondary_check')"
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id"
                                @click="
                                    dataObject.lottery_method.secondary_check = { id: '' };
                                    dataObject.lottery_method.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{
                                dataObject.lottery_method.secondary_check.name ? dataObject.lottery_method.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color5">対象データ</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.target_file.data"
                            :taggable="true"
                            :options="option.target_file"
                            @tag="addTag($event, 'target_file')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.target_file.first_check && dataObject.target_file.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'target_file', 'first_check')"
                            />
                            <span v-if="dataObject.target_file.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.first_check.id"
                                @click="
                                    dataObject.target_file.first_check = { id: '' };
                                    dataObject.target_file.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.target_file.first_check.id" class="ml-1">{{
                                dataObject.target_file.first_check.name ? dataObject.target_file.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.target_file.secondary_check && dataObject.target_file.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'target_file', 'secondary_check')"
                            />
                            <span v-if="dataObject.target_file.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.secondary_check.id"
                                @click="
                                    dataObject.target_file.secondary_check = { id: '' };
                                    dataObject.target_file.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.target_file.secondary_check.id" class="ml-1">{{
                                dataObject.target_file.secondary_check.name ? dataObject.target_file.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color5">■期間条件</td>
                    <td class="bg-color5">抽選対象期間</td>
                    <td colspan="4">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date) }}～{{ renderDate1(detailBeginForDocument.campaign_end_date) }}
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.period_conditions_lottery.first_check && dataObject.period_conditions_lottery.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_lottery.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions_lottery', 'first_check')"
                            />
                            <span v-if="dataObject.period_conditions_lottery.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_lottery.first_check.id"
                                @click="
                                    dataObject.period_conditions_lottery.first_check = { id: '' };
                                    dataObject.period_conditions_lottery.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions_lottery.first_check.id" class="ml-1">{{
                                dataObject.period_conditions_lottery.first_check.name ? dataObject.period_conditions_lottery.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.period_conditions_lottery.secondary_check && dataObject.period_conditions_lottery.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_lottery.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions_lottery', 'secondary_check')"
                            />
                            <span v-if="dataObject.period_conditions_lottery.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_lottery.secondary_check.id"
                                @click="
                                    dataObject.period_conditions_lottery.secondary_check = { id: '' };
                                    dataObject.period_conditions_lottery.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions_lottery.secondary_check.id" class="ml-1">{{
                                dataObject.period_conditions_lottery.secondary_check.name
                                    ? dataObject.period_conditions_lottery.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color5">消印有効日（ハガキ）</td>
                    <td colspan="4">
                        <div class="d-flex align-items-center mb-2">
                            <input
                                type="text"
                                v-model="dataObject.period_conditions_postcard.data_y"
                                style="width: 80px; padding-right: 0 !important"
                                class="form-control mr-2"
                            />
                            年
                            <input
                                type="text"
                                v-model="dataObject.period_conditions_postcard.data_m"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            月
                            <input
                                type="text"
                                v-model="dataObject.period_conditions_postcard.data_d"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            日
                            <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                カレンダー
                                <date-picker
                                    v-model="dataObject.period_conditions_postcard.data"
                                    :value-type="customFormatDate"
                                    :first-day-of-week="1"
                                    lang="ja"
                                ></date-picker>
                            </button>
                            から
                        </div>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.period_conditions_postcard.first_check && dataObject.period_conditions_postcard.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_postcard.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions_postcard', 'first_check')"
                            />
                            <span v-if="dataObject.period_conditions_postcard.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_postcard.first_check.id"
                                @click="
                                    dataObject.period_conditions_postcard.first_check = { id: '' };
                                    dataObject.period_conditions_postcard.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions_postcard.first_check.id" class="ml-1">{{
                                dataObject.period_conditions_postcard.first_check.name ? dataObject.period_conditions_postcard.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.period_conditions_postcard.secondary_check && dataObject.period_conditions_postcard.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_postcard.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions_postcard', 'secondary_check')"
                            />
                            <span v-if="dataObject.period_conditions_postcard.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions_postcard.secondary_check.id"
                                @click="
                                    dataObject.period_conditions_postcard.secondary_check = { id: '' };
                                    dataObject.period_conditions_postcard.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions_postcard.secondary_check.id" class="ml-1">{{
                                dataObject.period_conditions_postcard.secondary_check.name
                                    ? dataObject.period_conditions_postcard.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color5" rowspan="2">重複条件</td>
                    <td class="bg-color5">賞品またぎ重複</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.prize_overlap.data"
                            :taggable="true"
                            :options="option.prize_overlap"
                            @tag="addTag($event, 'prize_overlap')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.prize_overlap.first_check && dataObject.prize_overlap.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_overlap.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'prize_overlap', 'first_check')"
                            />
                            <span v-if="dataObject.prize_overlap.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_overlap.first_check.id"
                                @click="
                                    dataObject.prize_overlap.first_check = { id: '' };
                                    dataObject.prize_overlap.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.prize_overlap.first_check.id" class="ml-1">{{
                                dataObject.prize_overlap.first_check.name ? dataObject.prize_overlap.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.prize_overlap.secondary_check && dataObject.prize_overlap.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_overlap.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'prize_overlap', 'secondary_check')"
                            />
                            <span v-if="dataObject.prize_overlap.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.prize_overlap.secondary_check.id"
                                @click="
                                    dataObject.prize_overlap.secondary_check = { id: '' };
                                    dataObject.prize_overlap.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.prize_overlap.secondary_check.id" class="ml-1">{{
                                dataObject.prize_overlap.secondary_check.name ? dataObject.prize_overlap.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color5">流通またぎ重複</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.cross_distribution_overlap.data"
                            :taggable="true"
                            :options="option.cross_distribution_overlap"
                            @tag="addTag($event, 'cross_distribution_overlap')"
                        ></multiselect>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.cross_distribution_overlap.first_check && dataObject.cross_distribution_overlap.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution_overlap.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_distribution_overlap', 'first_check')"
                            />
                            <span v-if="dataObject.cross_distribution_overlap.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution_overlap.first_check.id"
                                @click="
                                    dataObject.cross_distribution_overlap.first_check = { id: '' };
                                    dataObject.cross_distribution_overlap.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_distribution_overlap.first_check.id" class="ml-1">{{
                                dataObject.cross_distribution_overlap.first_check.name ? dataObject.cross_distribution_overlap.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.cross_distribution_overlap.secondary_check && dataObject.cross_distribution_overlap.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution_overlap.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'cross_distribution_overlap', 'secondary_check')"
                            />
                            <span v-if="dataObject.cross_distribution_overlap.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.cross_distribution_overlap.secondary_check.id"
                                @click="
                                    dataObject.cross_distribution_overlap.secondary_check = { id: '' };
                                    dataObject.cross_distribution_overlap.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.cross_distribution_overlap.secondary_check.id" class="ml-1">{{
                                dataObject.cross_distribution_overlap.secondary_check.name
                                    ? dataObject.cross_distribution_overlap.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color5" rowspan="3" colspan="2">比率指定有無</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.ratio_specification_1.data"
                            :taggable="true"
                            :options="option.ratio_specification_1"
                            @tag="addTag($event, 'ratio_specification_1')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.ratio_specification_1.first_check && dataObject.ratio_specification_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_1', 'first_check')"
                            />
                            <span v-if="dataObject.ratio_specification_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_1.first_check.id"
                                @click="
                                    dataObject.ratio_specification_1.first_check = { id: '' };
                                    dataObject.ratio_specification_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_1.first_check.id" class="ml-1">{{
                                dataObject.ratio_specification_1.first_check.name ? dataObject.ratio_specification_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.ratio_specification_1.secondary_check && dataObject.ratio_specification_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.ratio_specification_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_1.secondary_check.id"
                                @click="
                                    dataObject.ratio_specification_1.secondary_check = { id: '' };
                                    dataObject.ratio_specification_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_1.secondary_check.id" class="ml-1">{{
                                dataObject.ratio_specification_1.secondary_check.name ? dataObject.ratio_specification_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.ratio_specification_2.data"
                            :taggable="true"
                            :options="option.ratio_specification_2"
                            @tag="addTag($event, 'ratio_specification_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.ratio_specification_2.first_check && dataObject.ratio_specification_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_2', 'first_check')"
                            />
                            <span v-if="dataObject.ratio_specification_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_2.first_check.id"
                                @click="
                                    dataObject.ratio_specification_2.first_check = { id: '' };
                                    dataObject.ratio_specification_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_2.first_check.id" class="ml-1">{{
                                dataObject.ratio_specification_2.first_check.name ? dataObject.ratio_specification_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.ratio_specification_2.secondary_check && dataObject.ratio_specification_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.ratio_specification_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_2.secondary_check.id"
                                @click="
                                    dataObject.ratio_specification_2.secondary_check = { id: '' };
                                    dataObject.ratio_specification_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_2.secondary_check.id" class="ml-1">{{
                                dataObject.ratio_specification_2.secondary_check.name ? dataObject.ratio_specification_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <textarea v-model="dataObject.ratio_specification_3.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.ratio_specification_3.first_check && dataObject.ratio_specification_3.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_3.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_3', 'first_check')"
                            />
                            <span v-if="dataObject.ratio_specification_3.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_3.first_check.id"
                                @click="
                                    dataObject.ratio_specification_3.first_check = { id: '' };
                                    dataObject.ratio_specification_3.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_3.first_check.id" class="ml-1">{{
                                dataObject.ratio_specification_3.first_check.name ? dataObject.ratio_specification_3.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.ratio_specification_3.secondary_check && dataObject.ratio_specification_3.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_3.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'ratio_specification_3', 'secondary_check')"
                            />
                            <span v-if="dataObject.ratio_specification_3.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.ratio_specification_3.secondary_check.id"
                                @click="
                                    dataObject.ratio_specification_3.secondary_check = { id: '' };
                                    dataObject.ratio_specification_3.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.ratio_specification_3.secondary_check.id" class="ml-1">{{
                                dataObject.ratio_specification_3.secondary_check.name ? dataObject.ratio_specification_3.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color5" colspan="2">その他条件</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.other_conditions.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.first_check && dataObject.other_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id"
                                @click="
                                    dataObject.other_conditions.first_check = { id: '' };
                                    dataObject.other_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{
                                dataObject.other_conditions.first_check.name ? dataObject.other_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.secondary_check && dataObject.other_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id"
                                @click="
                                    dataObject.other_conditions.secondary_check = { id: '' };
                                    dataObject.other_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.other_conditions.secondary_check.name ? dataObject.other_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="7">＜重複チェック＞</th>
                </tr>
                <template v-if="check1">
                    <tr>
                        <td class="bg-color6" rowspan="2">同一世帯</td>
                        <td class="bg-color6">確認項目</td>
                        <td colspan="4">
                            <textarea v-model="dataObject.same_household_1.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_household_1.first_check && dataObject.same_household_1.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_1.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_household_1', 'first_check')"
                                />
                                <span v-if="dataObject.same_household_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_1.first_check.id"
                                    @click="
                                        dataObject.same_household_1.first_check = { id: '' };
                                        dataObject.same_household_1.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_household_1.first_check.id" class="ml-1">{{
                                    dataObject.same_household_1.first_check.name ? dataObject.same_household_1.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_household_1.secondary_check && dataObject.same_household_1.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_1.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_household_1', 'secondary_check')"
                                />
                                <span v-if="dataObject.same_household_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_1.secondary_check.id"
                                    @click="
                                        dataObject.same_household_1.secondary_check = { id: '' };
                                        dataObject.same_household_1.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_household_1.secondary_check.id" class="ml-1">{{
                                    dataObject.same_household_1.secondary_check.name ? dataObject.same_household_1.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-color6">確認条件</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.same_household_2.data"
                                :taggable="true"
                                :options="option.same_household_2"
                                @tag="addTag($event, 'same_household_2')"
                            ></multiselect>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_household_2.first_check && dataObject.same_household_2.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_2.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_household_2', 'first_check')"
                                />
                                <span v-if="dataObject.same_household_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_2.first_check.id"
                                    @click="
                                        dataObject.same_household_2.first_check = { id: '' };
                                        dataObject.same_household_2.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_household_2.first_check.id" class="ml-1">{{
                                    dataObject.same_household_2.first_check.name ? dataObject.same_household_2.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_household_2.secondary_check && dataObject.same_household_2.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_2.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_household_2', 'secondary_check')"
                                />
                                <span v-if="dataObject.same_household_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_household_2.secondary_check.id"
                                    @click="
                                        dataObject.same_household_2.secondary_check = { id: '' };
                                        dataObject.same_household_2.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_household_2.secondary_check.id" class="ml-1">{{
                                    dataObject.same_household_2.secondary_check.name ? dataObject.same_household_2.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <template v-if="check2">
                    <tr>
                        <td class="bg-color6" rowspan="2">同一人物</td>
                        <td class="bg-color6">確認項目</td>
                        <td colspan="4">
                            <textarea v-model="dataObject.same_person_1.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_person_1.first_check && dataObject.same_person_1.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_1.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_person_1', 'first_check')"
                                />
                                <span v-if="dataObject.same_person_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_1.first_check.id"
                                    @click="
                                        dataObject.same_person_1.first_check = { id: '' };
                                        dataObject.same_person_1.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_person_1.first_check.id" class="ml-1">{{
                                    dataObject.same_person_1.first_check.name ? dataObject.same_person_1.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_person_1.secondary_check && dataObject.same_person_1.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_1.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_person_1', 'secondary_check')"
                                />
                                <span v-if="dataObject.same_person_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_1.secondary_check.id"
                                    @click="
                                        dataObject.same_person_1.secondary_check = { id: '' };
                                        dataObject.same_person_1.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_person_1.secondary_check.id" class="ml-1">{{
                                    dataObject.same_person_1.secondary_check.name ? dataObject.same_person_1.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-color6">確認条件</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.same_person_2.data"
                                :taggable="true"
                                :options="option.same_person_2"
                                @tag="addTag($event, 'same_person_2')"
                            ></multiselect>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_person_2.first_check && dataObject.same_person_2.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_2.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_person_2', 'first_check')"
                                />
                                <span v-if="dataObject.same_person_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_2.first_check.id"
                                    @click="
                                        dataObject.same_person_2.first_check = { id: '' };
                                        dataObject.same_person_2.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_person_2.first_check.id" class="ml-1">{{
                                    dataObject.same_person_2.first_check.name ? dataObject.same_person_2.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.same_person_2.secondary_check && dataObject.same_person_2.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_2.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'same_person_2', 'secondary_check')"
                                />
                                <span v-if="dataObject.same_person_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.same_person_2.secondary_check.id"
                                    @click="
                                        dataObject.same_person_2.secondary_check = { id: '' };
                                        dataObject.same_person_2.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.same_person_2.secondary_check.id" class="ml-1">{{
                                    dataObject.same_person_2.secondary_check.name ? dataObject.same_person_2.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <template v-if="check3">
                    <tr>
                        <td class="bg-color6" rowspan="2">その他</td>
                        <td class="bg-color6">確認項目</td>
                        <td colspan="4">
                            <textarea v-model="dataObject.others_duplicate_1.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="dataObject.others_duplicate_1.first_check && dataObject.others_duplicate_1.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_1.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'others_duplicate_1', 'first_check')"
                                />
                                <span v-if="dataObject.others_duplicate_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_1.first_check.id"
                                    @click="
                                        dataObject.others_duplicate_1.first_check = { id: '' };
                                        dataObject.others_duplicate_1.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.others_duplicate_1.first_check.id" class="ml-1">{{
                                    dataObject.others_duplicate_1.first_check.name ? dataObject.others_duplicate_1.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="dataObject.others_duplicate_1.secondary_check && dataObject.others_duplicate_1.secondary_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_1.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'others_duplicate_1', 'secondary_check')"
                                />
                                <span v-if="dataObject.others_duplicate_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_1.secondary_check.id"
                                    @click="
                                        dataObject.others_duplicate_1.secondary_check = { id: '' };
                                        dataObject.others_duplicate_1.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.others_duplicate_1.secondary_check.id" class="ml-1">{{
                                    dataObject.others_duplicate_1.secondary_check.name ? dataObject.others_duplicate_1.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-color6">確認条件</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.others_duplicate_2.data"
                                :taggable="true"
                                :options="option.others_duplicate_2"
                                @tag="addTag($event, 'others_duplicate_2')"
                            ></multiselect>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.others_duplicate_2.first_check && dataObject.others_duplicate_2.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_2.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'others_duplicate_2', 'first_check')"
                                />
                                <span v-if="dataObject.others_duplicate_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_2.first_check.id"
                                    @click="
                                        dataObject.others_duplicate_2.first_check = { id: '' };
                                        dataObject.others_duplicate_2.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.others_duplicate_2.first_check.id" class="ml-1">{{
                                    dataObject.others_duplicate_2.first_check.name ? dataObject.others_duplicate_2.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="dataObject.others_duplicate_2.secondary_check && dataObject.others_duplicate_2.secondary_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_2.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'others_duplicate_2', 'secondary_check')"
                                />
                                <span v-if="dataObject.others_duplicate_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.others_duplicate_2.secondary_check.id"
                                    @click="
                                        dataObject.others_duplicate_2.secondary_check = { id: '' };
                                        dataObject.others_duplicate_2.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.others_duplicate_2.secondary_check.id" class="ml-1">{{
                                    dataObject.others_duplicate_2.secondary_check.name ? dataObject.others_duplicate_2.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <template v-if="check4">
                    <tr>
                        <td class="bg-color6" rowspan="2">固有ID</td>
                        <td class="bg-color6">確認項目</td>
                        <td colspan="4">
                            <textarea v-model="dataObject.unique_id_1.data" class="form-control" />
                        </td>
                        <td>
                            <label for="" v-if="dataObject.unique_id_1.first_check && dataObject.unique_id_1.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_1.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'unique_id_1', 'first_check')"
                                />
                                <span v-if="dataObject.unique_id_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_1.first_check.id"
                                    @click="
                                        dataObject.unique_id_1.first_check = { id: '' };
                                        dataObject.unique_id_1.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.unique_id_1.first_check.id" class="ml-1">{{
                                    dataObject.unique_id_1.first_check.name ? dataObject.unique_id_1.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.unique_id_1.secondary_check && dataObject.unique_id_1.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_1.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'unique_id_1', 'secondary_check')"
                                />
                                <span v-if="dataObject.unique_id_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_1.secondary_check.id"
                                    @click="
                                        dataObject.unique_id_1.secondary_check = { id: '' };
                                        dataObject.unique_id_1.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.unique_id_1.secondary_check.id" class="ml-1">{{
                                    dataObject.unique_id_1.secondary_check.name ? dataObject.unique_id_1.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-color6">確認条件</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.unique_id_2.data"
                                :taggable="true"
                                :options="option.unique_id_2"
                                @tag="addTag($event, 'unique_id_2')"
                            ></multiselect>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.unique_id_2.first_check && dataObject.unique_id_2.first_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_2.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'unique_id_2', 'first_check')"
                                />
                                <span v-if="dataObject.unique_id_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_2.first_check.id"
                                    @click="
                                        dataObject.unique_id_2.first_check = { id: '' };
                                        dataObject.unique_id_2.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.unique_id_2.first_check.id" class="ml-1">{{
                                    dataObject.unique_id_2.first_check.name ? dataObject.unique_id_2.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label for="" v-if="dataObject.unique_id_2.secondary_check && dataObject.unique_id_2.secondary_check.change">
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_2.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'unique_id_2', 'secondary_check')"
                                />
                                <span v-if="dataObject.unique_id_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.unique_id_2.secondary_check.id"
                                    @click="
                                        dataObject.unique_id_2.secondary_check = { id: '' };
                                        dataObject.unique_id_2.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.unique_id_2.secondary_check.id" class="ml-1">{{
                                    dataObject.unique_id_2.secondary_check.name ? dataObject.unique_id_2.secondary_check.name : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <tr>
                    <td class="bg-color6" colspan="2" rowspan="2">その他データとの付け合わせ確認</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.matching_with_other_data_1.data"
                            :taggable="true"
                            :options="option.matching_with_other_data_1"
                            @tag="addTag($event, 'matching_with_other_data_1')"
                        ></multiselect>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.matching_with_other_data_1.first_check && dataObject.matching_with_other_data_1.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_with_other_data_1', 'first_check')"
                            />
                            <span v-if="dataObject.matching_with_other_data_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_1.first_check.id"
                                @click="
                                    dataObject.matching_with_other_data_1.first_check = { id: '' };
                                    dataObject.matching_with_other_data_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_with_other_data_1.first_check.id" class="ml-1">{{
                                dataObject.matching_with_other_data_1.first_check.name ? dataObject.matching_with_other_data_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.matching_with_other_data_1.secondary_check && dataObject.matching_with_other_data_1.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_with_other_data_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.matching_with_other_data_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_1.secondary_check.id"
                                @click="
                                    dataObject.matching_with_other_data_1.secondary_check = { id: '' };
                                    dataObject.matching_with_other_data_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_with_other_data_1.secondary_check.id" class="ml-1">{{
                                dataObject.matching_with_other_data_1.secondary_check.name
                                    ? dataObject.matching_with_other_data_1.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <textarea v-model="dataObject.matching_with_other_data_2.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.matching_with_other_data_2.first_check && dataObject.matching_with_other_data_2.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_with_other_data_2', 'first_check')"
                            />
                            <span v-if="dataObject.matching_with_other_data_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_2.first_check.id"
                                @click="
                                    dataObject.matching_with_other_data_2.first_check = { id: '' };
                                    dataObject.matching_with_other_data_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_with_other_data_2.first_check.id" class="ml-1">{{
                                dataObject.matching_with_other_data_2.first_check.name ? dataObject.matching_with_other_data_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.matching_with_other_data_2.secondary_check && dataObject.matching_with_other_data_2.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_with_other_data_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.matching_with_other_data_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_with_other_data_2.secondary_check.id"
                                @click="
                                    dataObject.matching_with_other_data_2.secondary_check = { id: '' };
                                    dataObject.matching_with_other_data_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_with_other_data_2.secondary_check.id" class="ml-1">{{
                                dataObject.matching_with_other_data_2.secondary_check.name
                                    ? dataObject.matching_with_other_data_2.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="7">＜不備チェック＞</th>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">名前不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_error_name.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.first_check && dataObject.confirm_error_name.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id"
                                @click="
                                    dataObject.confirm_error_name.first_check = { id: '' };
                                    dataObject.confirm_error_name.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.first_check.name ? dataObject.confirm_error_name.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.secondary_check && dataObject.confirm_error_name.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id"
                                @click="
                                    dataObject.confirm_error_name.secondary_check = { id: '' };
                                    dataObject.confirm_error_name.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.secondary_check.name ? dataObject.confirm_error_name.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color6" colspan="2" rowspan="2">住所不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.address_verification_1.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_1.first_check && dataObject.address_verification_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id"
                                @click="
                                    dataObject.address_verification_1.first_check = { id: '' };
                                    dataObject.address_verification_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{
                                dataObject.address_verification_1.first_check.name ? dataObject.address_verification_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_1.secondary_check && dataObject.address_verification_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id"
                                @click="
                                    dataObject.address_verification_1.secondary_check = { id: '' };
                                    dataObject.address_verification_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_1.secondary_check.name ? dataObject.address_verification_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.address_verification_2.data"
                            :taggable="true"
                            :options="option.address_verification_2"
                            @tag="addTag($event, 'address_verification_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_2.first_check && dataObject.address_verification_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id"
                                @click="
                                    dataObject.address_verification_2.first_check = { id: '' };
                                    dataObject.address_verification_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{
                                dataObject.address_verification_2.first_check.name ? dataObject.address_verification_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_2.secondary_check && dataObject.address_verification_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id"
                                @click="
                                    dataObject.address_verification_2.secondary_check = { id: '' };
                                    dataObject.address_verification_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_2.secondary_check.name ? dataObject.address_verification_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">電話番号不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_phone_number_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.first_check &&
                                dataObject.confirm_phone_number_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.secondary_check &&
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">メールアドレス不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_email_address_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.first_check &&
                                dataObject.confirm_email_address_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.first_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.secondary_check &&
                                dataObject.confirm_email_address_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">その他</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.other_errors.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.first_check && dataObject.other_errors.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'first_check')"
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id"
                                @click="
                                    dataObject.other_errors.first_check = { id: '' };
                                    dataObject.other_errors.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{
                                dataObject.other_errors.first_check.name ? dataObject.other_errors.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.secondary_check && dataObject.other_errors.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id"
                                @click="
                                    dataObject.other_errors.secondary_check = { id: '' };
                                    dataObject.other_errors.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{
                                dataObject.other_errors.secondary_check.name ? dataObject.other_errors.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜購買チェック＞</th>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">購入日</td>
                    <td colspan="4">
                        <div class="d-flex align-items-center mb-2">
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.start_date_y"
                                style="width: 80px; padding-right: 0 !important"
                                class="form-control mr-2"
                            />
                            年
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.start_date_m"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            月
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.start_date_d"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            日
                            <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                カレンダー
                                <date-picker
                                    v-model="dataObject.purchase_date.data.start_date"
                                    :value-type="customFormatDate"
                                    :first-day-of-week="1"
                                    lang="ja"
                                ></date-picker>
                            </button>
                            から
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.end_date_y"
                                style="width: 80px; padding-right: 0 !important"
                                class="form-control mr-2"
                            />
                            年
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.end_date_m"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            月
                            <input
                                type="text"
                                v-model="dataObject.purchase_date.data.end_date_d"
                                style="width: 47px; padding-right: 0 !important"
                                class="form-control mx-2"
                            />
                            日
                            <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                カレンダー
                                <date-picker
                                    v-model="dataObject.purchase_date.data.end_date"
                                    :value-type="customFormatDate"
                                    :first-day-of-week="1"
                                    lang="ja"
                                ></date-picker>
                            </button>
                            まで
                        </div>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_date.first_check && dataObject.purchase_date.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_date.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_date', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_date.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_date.first_check.id"
                                @click="
                                    dataObject.purchase_date.first_check = { id: '' };
                                    dataObject.purchase_date.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_date.first_check.id" class="ml-1">{{
                                dataObject.purchase_date.first_check.name ? dataObject.purchase_date.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_date.secondary_check && dataObject.purchase_date.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_date.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_date', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_date.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_date.secondary_check.id"
                                @click="
                                    dataObject.purchase_date.secondary_check = { id: '' };
                                    dataObject.purchase_date.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_date.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_date.secondary_check.name ? dataObject.purchase_date.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">対象商品</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.shipping.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.shipping.first_check && dataObject.shipping.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'shipping', 'first_check')"
                            />
                            <span v-if="dataObject.shipping.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.first_check.id"
                                @click="
                                    dataObject.shipping.first_check = { id: '' };
                                    dataObject.shipping.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.shipping.first_check.id" class="ml-1">{{
                                dataObject.shipping.first_check.name ? dataObject.shipping.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.shipping.secondary_check && dataObject.shipping.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'shipping', 'secondary_check')"
                            />
                            <span v-if="dataObject.shipping.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.shipping.secondary_check.id"
                                @click="
                                    dataObject.shipping.secondary_check = { id: '' };
                                    dataObject.shipping.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.shipping.secondary_check.id" class="ml-1">{{
                                dataObject.shipping.secondary_check.name ? dataObject.shipping.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">購入金額</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.purchase_price.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_price.first_check && dataObject.purchase_price.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_price.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_price', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_price.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_price.first_check.id"
                                @click="
                                    dataObject.purchase_price.first_check = { id: '' };
                                    dataObject.purchase_price.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_price.first_check.id" class="ml-1">{{
                                dataObject.purchase_price.first_check.name ? dataObject.purchase_price.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_price.secondary_check && dataObject.purchase_price.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_price.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_price', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_price.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_price.secondary_check.id"
                                @click="
                                    dataObject.purchase_price.secondary_check = { id: '' };
                                    dataObject.purchase_price.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_price.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_price.secondary_check.name ? dataObject.purchase_price.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">購入店舗・流通</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.purchase_store_or_distribution.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.purchase_store_or_distribution.first_check && dataObject.purchase_store_or_distribution.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_store_or_distribution', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.first_check.id"
                                @click="
                                    dataObject.purchase_store_or_distribution.first_check = { id: '' };
                                    dataObject.purchase_store_or_distribution.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.first_check.id" class="ml-1">{{
                                dataObject.purchase_store_or_distribution.first_check.name
                                    ? dataObject.purchase_store_or_distribution.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.purchase_store_or_distribution.secondary_check &&
                                dataObject.purchase_store_or_distribution.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_store_or_distribution', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_store_or_distribution.secondary_check.id"
                                @click="
                                    dataObject.purchase_store_or_distribution.secondary_check = { id: '' };
                                    dataObject.purchase_store_or_distribution.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_store_or_distribution.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_store_or_distribution.secondary_check.name
                                    ? dataObject.purchase_store_or_distribution.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color6" colspan="2">その他</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.purchase_info_other.data" class="form-control" />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_info_other.first_check && dataObject.purchase_info_other.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other', 'first_check')"
                            />
                            <span v-if="dataObject.purchase_info_other.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.first_check.id"
                                @click="
                                    dataObject.purchase_info_other.first_check = { id: '' };
                                    dataObject.purchase_info_other.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other.first_check.id" class="ml-1">{{
                                dataObject.purchase_info_other.first_check.name ? dataObject.purchase_info_other.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.purchase_info_other.secondary_check && dataObject.purchase_info_other.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'purchase_info_other', 'secondary_check')"
                            />
                            <span v-if="dataObject.purchase_info_other.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.purchase_info_other.secondary_check.id"
                                @click="
                                    dataObject.purchase_info_other.secondary_check = { id: '' };
                                    dataObject.purchase_info_other.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.purchase_info_other.secondary_check.id" class="ml-1">{{
                                dataObject.purchase_info_other.secondary_check.name ? dataObject.purchase_info_other.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';

export default {
    name: 'Tab7Require',
    props: {
        check1: {
            type: Boolean,
            required: false
        },
        check2: {
            type: Boolean,
            required: false
        },
        check3: {
            type: Boolean,
            required: false
        },
        check4: {
            type: Boolean,
            required: false
        },
        dataObject: {
            type: Object,
            required: false
        },
        currentUser: {
            type: Object,
            required: false
        },
        detailBeginForDocument: {
            type: Object,
            required: false
        }
    },
    components: { Multiselect, DatePicker },
    data() {
        return {
            customFormatDate: 'YYYY-MM-DD',
            option: {
                lottery_method: ['データ抽選', 'ハガキ引き抜き'],
                target_file: ['HP入力データ', 'エイジアフォーム', 'その他フォーム ', '支給データ'],
                winning_conditions: ['クイズに正解している人', 'アンケートに回答している人', 'その他'],
                prize_overlap: ['重複確認する', '重複確認しない'],
                cross_distribution_overlap: ['重複確認する', '重複確認しない'],
                ratio_specification_1: ['あり', 'なし'],
                ratio_specification_2: ['賞品', '応募形態', '口数', '流通・店舗', '都道府県', '年齢', '性別', 'アンケート回答'],
                same_household_2: ['完全一致', ' 近似値目チェック'],
                same_person_2: ['完全一致', ' 近似値目チェック'],
                others_duplicate_2: ['完全一致', ' 近似値目チェック'],
                unique_id_2: ['完全一致', ' 近似値目チェック'],
                matching_with_other_data_1: ['あり', 'なし'],
                address_verification_2: ['システムチェック', '近似値目チェック']
            }
        };
    },
    methods: {
        renderDate(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                return `${year} 年 ${month} 月 ${day} 日`;
            }
            return '';
        },
        renderDate1(time) {
            const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];
            const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = addLeadingZero(date.getHours());
                const minutes = addLeadingZero(date.getMinutes());
                const dayOfWeek = date.getDay();
                const dayNameInJapanese = daysInJapanese[dayOfWeek];
                return `${year} 年 ${month} 月 ${day} 日（${dayNameInJapanese}）${hours}：${minutes}`;
            }
            return '';
        },
        addTag(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].data.push(newTag);
        },
        updateCheckbox(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject[key][key2].id = value;
            } else {
                this.dataObject[key][key2].id = null;
            }
        },
        updateCheckbox1(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject.prizes.data[key][key2].id = value;
            } else {
                this.dataObject.prizes.data[key][key2].id = null;
            }
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
