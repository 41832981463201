<template>
    <div class="table-responsive">
        <table class="table-bordered table">
            <tbody>
                <tr>
                    <th colspan="8">＜CP概要（Twitter）＞</th>
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン名</td>
                    <td colspan="7">{{ detailBeginForDocument.name }}</td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">クライアント/代理店名</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.clients_formatted
                        }}{{ detailBeginForDocument.agencies_formatted ? '/' + detailBeginForDocument.agencies_formatted : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">キャンペーン期間</td>
                    <td colspan="7">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date)
                        }}{{ detailBeginForDocument.campaign_end_date ? '～' + renderDate1(detailBeginForDocument.campaign_end_date) : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">CPサイト・CPツイート</td>
                    <td colspan="7">{{ detailBeginForDocument.url }}</td>
                    <!--                    <td colspan="2" class="text-danger">>※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">ディレクション担当</td>
                    <td colspan="7">
                        {{ detailBeginForDocument.person_in_charge ? detailBeginForDocument.person_in_charge.name : '' }}
                    </td>
                    <!--                    <td colspan="2" class="text-danger">>※表紙に記載しているのであれば不要</td>-->
                </tr>
                <tr>
                    <td colspan="1" class="bg-color2">営業担当</td>
                    <td colspan="7">{{ detailBeginForDocument.sale_staff ? detailBeginForDocument.sale_staff.name : '' }}</td>
                    <!--                    <td colspan="2" class="text-danger">>※表紙に記載しているのであれば不要</td>-->
                </tr>

                <tr>
                    <th colspan="8">＜抽選条件＞※応募割れの場合は抽選条件が変わる可能性があります。</th>
                </tr>
                <tr>
                    <th :rowspan="dataObject.hashtags.data.length + 1" class="bg-color3">当選人数</th>
                    <th class="bg-color3"></th>
                    <th class="bg-color3">当選者</th>
                    <th class="bg-color3">予備当選者</th>
                    <th colspan="2">計</th>
                    <th>1次チェック</th>
                    <th>2次チェック</th>
                </tr>
                <tr v-for="(item, k) in dataObject.hashtags.data" :key="k + 'aa'">
                    <td class="bg-color3">{{ item.name }}賞</td>

                    <td>
                        <input type="number" v-model="dataObject.hashtags.data[k].number_of_win" class="form-control" />
                    </td>
                    <td>
                        <input type="number" v-model="dataObject.hashtags.data[k].preliminary_winner" class="form-control" />
                    </td>
                    <td>
                        {{ item.total_winner }}
                    </td>
                    <td v-if="k == 0" :rowspan="dataObject.hashtags.data.length" align="middle" valign="middle">
                        {{ dataObject.hashtags.sum_total_winner }}
                    </td>
                    <td>
                        <label for="" v-if="item.first_check && item.first_check.change">
                            <input
                                type="checkbox"
                                :checked="item.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox1($event, currentUser.id, k, 'first_check')"
                            />
                            <span v-if="item.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="item.first_check.id"
                                @click="
                                    dataObject.hashtags.data[k].first_check = { id: '' };
                                    dataObject.hashtags.data[k].first_check.change = true;
                                "
                            />
                            <span v-if="item.first_check.id" class="ml-1">{{ item.first_check.name ? item.first_check.name : '' }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="item.secondary_check && item.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="item.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox1($event, currentUser.id, k, 'secondary_check')"
                            />
                            <span v-if="item.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="item.secondary_check.id"
                                @click="
                                    dataObject.hashtags.data[k].secondary_check = { id: '' };
                                    dataObject.hashtags.data[k].secondary_check.change = true;
                                "
                            />
                            <span v-if="item.secondary_check.id" class="ml-1">{{ item.secondary_check.name ? item.secondary_check.name : '' }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <th rowspan="3" class="bg-color3">RT</th>
                    <th class="bg-color3"></th>
                    <th class="bg-color3">当選者</th>
                    <th class="bg-color3">予備当選者</th>
                    <th colspan="2">計</th>
                    <th></th>
                    <th></th>
                </tr>
                <tr v-for="(item, k) in dataObject.twitter_rt.data" :key="k + 'aad'">
                    <td class="bg-color3">#{{ item.name }}</td>

                    <td>
                        <input type="number" v-model="dataObject.twitter_rt.data[k].number_of_win" class="form-control" />
                    </td>
                    <td>
                        <input type="number" v-model="dataObject.twitter_rt.data[k].preliminary_winner" class="form-control" />
                    </td>
                    <td>
                        {{ item.total_winner }}
                    </td>
                    <td v-if="k == 0" :rowspan="dataObject.twitter_rt.data.length" align="middle" valign="middle">
                        {{ dataObject.twitter_rt.sum_total_winner }}
                    </td>
                    <td>
                        <label for="" v-if="item.first_check && item.first_check.change">
                            <input
                                type="checkbox"
                                :checked="item.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox2($event, currentUser.id, k, 'first_check')"
                            />
                            <span v-if="item.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="item.first_check.id"
                                @click="
                                    dataObject.twitter_rt.data[k].first_check = { id: '' };
                                    dataObject.twitter_rt.data[k].first_check.change = true;
                                "
                            />
                            <span v-if="item.first_check.id" class="ml-1">{{ item.first_check.name ? item.first_check.name : '' }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="item.secondary_check && item.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="item.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox2($event, currentUser.id, k, 'secondary_check')"
                            />
                            <span v-if="item.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="item.secondary_check.id"
                                @click="
                                    dataObject.twitter_rt.data[k].secondary_check = { id: '' };
                                    dataObject.twitter_rt.data[k].secondary_check.change = true;
                                "
                            />
                            <span v-if="item.secondary_check.id" class="ml-1">{{ item.secondary_check.name ? item.secondary_check.name : '' }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" class="bg-color3">抽選方法</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.lottery_method.data" class="form-control" readonly />
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.first_check && dataObject.lottery_method.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'first_check')"
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.first_check.id"
                                @click="
                                    dataObject.lottery_method.first_check = { id: '' };
                                    dataObject.lottery_method.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.first_check.id" class="ml-1">{{
                                dataObject.lottery_method.first_check.name ? dataObject.lottery_method.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.lottery_method.secondary_check && dataObject.lottery_method.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'lottery_method', 'secondary_check')"
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.lottery_method.secondary_check.id"
                                @click="
                                    dataObject.lottery_method.secondary_check = { id: '' };
                                    dataObject.lottery_method.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.lottery_method.secondary_check.id" class="ml-1">{{
                                dataObject.lottery_method.secondary_check.name ? dataObject.lottery_method.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">対象ファイル</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.target_file.data"
                            :taggable="true"
                            :options="option.target_file"
                            @tag="addTag($event, 'target_file')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.target_file.first_check && dataObject.target_file.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'target_file', 'first_check')"
                            />
                            <span v-if="dataObject.target_file.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.first_check.id"
                                @click="
                                    dataObject.target_file.first_check = { id: '' };
                                    dataObject.target_file.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.target_file.first_check.id" class="ml-1">{{
                                dataObject.target_file.first_check.name ? dataObject.target_file.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.target_file.secondary_check && dataObject.target_file.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'target_file', 'secondary_check')"
                            />
                            <span v-if="dataObject.target_file.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.target_file.secondary_check.id"
                                @click="
                                    dataObject.target_file.secondary_check = { id: '' };
                                    dataObject.target_file.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.target_file.secondary_check.id" class="ml-1">{{
                                dataObject.target_file.secondary_check.name ? dataObject.target_file.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">期間条件（抽選対象期間）</td>
                    <td colspan="4">
                        {{ renderDate1(detailBeginForDocument.campaign_start_date) }}～{{ renderDate1(detailBeginForDocument.campaign_end_date) }}
                    </td>
                    <td>
                        <label for="" v-if="dataObject.period_conditions.first_check && dataObject.period_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.period_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions.first_check.id"
                                @click="
                                    dataObject.period_conditions.first_check = { id: '' };
                                    dataObject.period_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions.first_check.id" class="ml-1">{{
                                dataObject.period_conditions.first_check.name ? dataObject.period_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.period_conditions.secondary_check && dataObject.period_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'period_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.period_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.period_conditions.secondary_check.id"
                                @click="
                                    dataObject.period_conditions.secondary_check = { id: '' };
                                    dataObject.period_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.period_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.period_conditions.secondary_check.name ? dataObject.period_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" class="bg-color3">除外アカウント</td>
                    <td colspan="4"><textarea v-model="dataObject.excluded_accounts.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.excluded_accounts.first_check && dataObject.excluded_accounts.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.excluded_accounts.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'excluded_accounts', 'first_check')"
                            />
                            <span v-if="dataObject.excluded_accounts.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.excluded_accounts.first_check.id"
                                @click="
                                    dataObject.excluded_accounts.first_check = { id: '' };
                                    dataObject.excluded_accounts.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.excluded_accounts.first_check.id" class="ml-1">{{
                                dataObject.excluded_accounts.first_check.name ? dataObject.excluded_accounts.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.excluded_accounts.secondary_check && dataObject.excluded_accounts.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.excluded_accounts.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'excluded_accounts', 'secondary_check')"
                            />
                            <span v-if="dataObject.excluded_accounts.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.excluded_accounts.secondary_check.id"
                                @click="
                                    dataObject.excluded_accounts.secondary_check = { id: '' };
                                    dataObject.excluded_accounts.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.excluded_accounts.secondary_check.id" class="ml-1">{{
                                dataObject.excluded_accounts.secondary_check.name ? dataObject.excluded_accounts.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="7" class="bg-color3">アカウント・投稿情報</td>
                    <td class="bg-color3">公式アカウントフォローチェック</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.official_account_follow_check.data"
                            :taggable="true"
                            :options="option.official_account_follow_check"
                            @tag="addTag($event, 'official_account_follow_check')"
                        ></multiselect>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.official_account_follow_check.first_check && dataObject.official_account_follow_check.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.official_account_follow_check.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'official_account_follow_check', 'first_check')"
                            />
                            <span v-if="dataObject.official_account_follow_check.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.official_account_follow_check.first_check.id"
                                @click="
                                    dataObject.official_account_follow_check.first_check = { id: '' };
                                    dataObject.official_account_follow_check.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.official_account_follow_check.first_check.id" class="ml-1">{{
                                dataObject.official_account_follow_check.first_check.name
                                    ? dataObject.official_account_follow_check.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.official_account_follow_check.secondary_check &&
                                dataObject.official_account_follow_check.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.official_account_follow_check.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'official_account_follow_check', 'secondary_check')"
                            />
                            <span v-if="dataObject.official_account_follow_check.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.official_account_follow_check.secondary_check.id"
                                @click="
                                    dataObject.official_account_follow_check.secondary_check = { id: '' };
                                    dataObject.official_account_follow_check.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.official_account_follow_check.secondary_check.id" class="ml-1">{{
                                dataObject.official_account_follow_check.secondary_check.name
                                    ? dataObject.official_account_follow_check.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color3">フォロワー数</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.number_of_followers.data"
                            :taggable="true"
                            :options="option.number_of_followers"
                            @tag="addTag($event, 'number_of_followers')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.number_of_followers.first_check && dataObject.number_of_followers.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_followers.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_followers', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_followers.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_followers.first_check.id"
                                @click="
                                    dataObject.number_of_followers.first_check = { id: '' };
                                    dataObject.number_of_followers.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_followers.first_check.id" class="ml-1">{{
                                dataObject.number_of_followers.first_check.name ? dataObject.number_of_followers.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.number_of_followers.secondary_check && dataObject.number_of_followers.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_followers.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_followers', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_followers.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_followers.secondary_check.id"
                                @click="
                                    dataObject.number_of_followers.secondary_check = { id: '' };
                                    dataObject.number_of_followers.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_followers.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_followers.secondary_check.name ? dataObject.number_of_followers.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.number_of_follower_detail.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.number_of_follower_detail.first_check && dataObject.number_of_follower_detail.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_follower_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_follower_detail', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_follower_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_follower_detail.first_check.id"
                                @click="
                                    dataObject.number_of_follower_detail.first_check = { id: '' };
                                    dataObject.number_of_follower_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_follower_detail.first_check.id" class="ml-1">{{
                                dataObject.number_of_follower_detail.first_check.name ? dataObject.number_of_follower_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.number_of_follower_detail.secondary_check && dataObject.number_of_follower_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_follower_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_follower_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_follower_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_follower_detail.secondary_check.id"
                                @click="
                                    dataObject.number_of_follower_detail.secondary_check = { id: '' };
                                    dataObject.number_of_follower_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_follower_detail.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_follower_detail.secondary_check.name
                                    ? dataObject.number_of_follower_detail.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color3">投稿数</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.number_of_posts.data"
                            :taggable="true"
                            :options="option.number_of_posts"
                            @tag="addTag($event, 'number_of_posts')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.number_of_posts.first_check && dataObject.number_of_posts.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_posts.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_posts', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_posts.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_posts.first_check.id"
                                @click="
                                    dataObject.number_of_posts.first_check = { id: '' };
                                    dataObject.number_of_posts.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_posts.first_check.id" class="ml-1">{{
                                dataObject.number_of_posts.first_check.name ? dataObject.number_of_posts.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.number_of_posts.secondary_check && dataObject.number_of_posts.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_posts.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_posts', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_posts.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_posts.secondary_check.id"
                                @click="
                                    dataObject.number_of_posts.secondary_check = { id: '' };
                                    dataObject.number_of_posts.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_posts.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_posts.secondary_check.name ? dataObject.number_of_posts.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.number_of_post_detail.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.number_of_post_detail.first_check && dataObject.number_of_post_detail.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_post_detail.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_post_detail', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_post_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_post_detail.first_check.id"
                                @click="
                                    dataObject.number_of_post_detail.first_check = { id: '' };
                                    dataObject.number_of_post_detail.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_post_detail.first_check.id" class="ml-1">{{
                                dataObject.number_of_post_detail.first_check.name ? dataObject.number_of_post_detail.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.number_of_post_detail.secondary_check && dataObject.number_of_post_detail.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_post_detail.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_post_detail', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_post_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_post_detail.secondary_check.id"
                                @click="
                                    dataObject.number_of_post_detail.secondary_check = { id: '' };
                                    dataObject.number_of_post_detail.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_post_detail.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_post_detail.secondary_check.name ? dataObject.number_of_post_detail.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td class="bg-color3">投稿内必須ワード</td>
                    <td colspan="4"><textarea v-model="dataObject.required_words_in_the_post.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.required_words_in_the_post.first_check && dataObject.required_words_in_the_post.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.required_words_in_the_post.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'required_words_in_the_post', 'first_check')"
                            />
                            <span v-if="dataObject.required_words_in_the_post.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.required_words_in_the_post.first_check.id"
                                @click="
                                    dataObject.required_words_in_the_post.first_check = { id: '' };
                                    dataObject.required_words_in_the_post.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.required_words_in_the_post.first_check.id" class="ml-1">{{
                                dataObject.required_words_in_the_post.first_check.name ? dataObject.required_words_in_the_post.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.required_words_in_the_post.secondary_check && dataObject.required_words_in_the_post.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.required_words_in_the_post.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'required_words_in_the_post', 'secondary_check')"
                            />
                            <span v-if="dataObject.required_words_in_the_post.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.required_words_in_the_post.secondary_check.id"
                                @click="
                                    dataObject.required_words_in_the_post.secondary_check = { id: '' };
                                    dataObject.required_words_in_the_post.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.required_words_in_the_post.secondary_check.id" class="ml-1">{{
                                dataObject.required_words_in_the_post.secondary_check.name
                                    ? dataObject.required_words_in_the_post.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td class="bg-color3">投稿文文字数</td>
                    <td colspan="4"><textarea v-model="dataObject.post_word_count.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.post_word_count.first_check && dataObject.post_word_count.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.post_word_count.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_word_count', 'first_check')"
                            />
                            <span v-if="dataObject.post_word_count.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_word_count.first_check.id"
                                @click="
                                    dataObject.post_word_count.first_check = { id: '' };
                                    dataObject.post_word_count.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_word_count.first_check.id" class="ml-1">{{
                                dataObject.post_word_count.first_check.name ? dataObject.post_word_count.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.post_word_count.secondary_check && dataObject.post_word_count.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.post_word_count.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'post_word_count', 'secondary_check')"
                            />
                            <span v-if="dataObject.post_word_count.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.post_word_count.secondary_check.id"
                                @click="
                                    dataObject.post_word_count.secondary_check = { id: '' };
                                    dataObject.post_word_count.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.post_word_count.secondary_check.id" class="ml-1">{{
                                dataObject.post_word_count.secondary_check.name ? dataObject.post_word_count.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" class="bg-color3">その他条件</td>
                    <td colspan="4"><textarea v-model="dataObject.other_conditions.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.first_check && dataObject.other_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.first_check.id"
                                @click="
                                    dataObject.other_conditions.first_check = { id: '' };
                                    dataObject.other_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.first_check.id" class="ml-1">{{
                                dataObject.other_conditions.first_check.name ? dataObject.other_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_conditions.secondary_check && dataObject.other_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_conditions.secondary_check.id"
                                @click="
                                    dataObject.other_conditions.secondary_check = { id: '' };
                                    dataObject.other_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.other_conditions.secondary_check.name ? dataObject.other_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜画像検閲＞</th>
                </tr>
                <tr>
                    <td colspan="2" rowspan="3" class="bg-color3">指定条件</td>
                    <td colspan="4"><textarea v-model="dataObject.specified_condition_1.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.specified_condition_1.first_check && dataObject.specified_condition_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_1', 'first_check')"
                            />
                            <span v-if="dataObject.specified_condition_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_1.first_check.id"
                                @click="
                                    dataObject.specified_condition_1.first_check = { id: '' };
                                    dataObject.specified_condition_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_1.first_check.id" class="ml-1">{{
                                dataObject.specified_condition_1.first_check.name ? dataObject.specified_condition_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.specified_condition_1.secondary_check && dataObject.specified_condition_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.specified_condition_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_1.secondary_check.id"
                                @click="
                                    dataObject.specified_condition_1.secondary_check = { id: '' };
                                    dataObject.specified_condition_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_1.secondary_check.id" class="ml-1">{{
                                dataObject.specified_condition_1.secondary_check.name ? dataObject.specified_condition_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.specified_condition_2.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.specified_condition_2.first_check && dataObject.specified_condition_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_2', 'first_check')"
                            />
                            <span v-if="dataObject.specified_condition_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_2.first_check.id"
                                @click="
                                    dataObject.specified_condition_2.first_check = { id: '' };
                                    dataObject.specified_condition_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_2.first_check.id" class="ml-1">{{
                                dataObject.specified_condition_2.first_check.name ? dataObject.specified_condition_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.specified_condition_2.secondary_check && dataObject.specified_condition_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.specified_condition_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_2.secondary_check.id"
                                @click="
                                    dataObject.specified_condition_2.secondary_check = { id: '' };
                                    dataObject.specified_condition_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_2.secondary_check.id" class="ml-1">{{
                                dataObject.specified_condition_2.secondary_check.name ? dataObject.specified_condition_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.specified_condition_3.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.specified_condition_3.first_check && dataObject.specified_condition_3.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_3.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_3', 'first_check')"
                            />
                            <span v-if="dataObject.specified_condition_3.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_3.first_check.id"
                                @click="
                                    dataObject.specified_condition_3.first_check = { id: '' };
                                    dataObject.specified_condition_3.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_3.first_check.id" class="ml-1">{{
                                dataObject.specified_condition_3.first_check.name ? dataObject.specified_condition_3.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.specified_condition_3.secondary_check && dataObject.specified_condition_3.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_3.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_condition_3', 'secondary_check')"
                            />
                            <span v-if="dataObject.specified_condition_3.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_condition_3.secondary_check.id"
                                @click="
                                    dataObject.specified_condition_3.secondary_check = { id: '' };
                                    dataObject.specified_condition_3.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_condition_3.secondary_check.id" class="ml-1">{{
                                dataObject.specified_condition_3.secondary_check.name ? dataObject.specified_condition_3.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" rowspan="2" class="bg-color3">確認件数</td>
                    <td colspan="4"><textarea v-model="dataObject.number_of_confirmed_cases_1.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.number_of_confirmed_cases_1.first_check && dataObject.number_of_confirmed_cases_1.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_confirmed_cases_1', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_1.first_check.id"
                                @click="
                                    dataObject.number_of_confirmed_cases_1.first_check = { id: '' };
                                    dataObject.number_of_confirmed_cases_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_1.first_check.id" class="ml-1">{{
                                dataObject.number_of_confirmed_cases_1.first_check.name ? dataObject.number_of_confirmed_cases_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.number_of_confirmed_cases_1.secondary_check &&
                                dataObject.number_of_confirmed_cases_1.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_confirmed_cases_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_1.secondary_check.id"
                                @click="
                                    dataObject.number_of_confirmed_cases_1.secondary_check = { id: '' };
                                    dataObject.number_of_confirmed_cases_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_1.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_confirmed_cases_1.secondary_check.name
                                    ? dataObject.number_of_confirmed_cases_1.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.number_of_confirmed_cases_2.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.number_of_confirmed_cases_2.first_check && dataObject.number_of_confirmed_cases_2.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_confirmed_cases_2', 'first_check')"
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_2.first_check.id"
                                @click="
                                    dataObject.number_of_confirmed_cases_2.first_check = { id: '' };
                                    dataObject.number_of_confirmed_cases_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_2.first_check.id" class="ml-1">{{
                                dataObject.number_of_confirmed_cases_2.first_check.name ? dataObject.number_of_confirmed_cases_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.number_of_confirmed_cases_2.secondary_check &&
                                dataObject.number_of_confirmed_cases_2.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'number_of_confirmed_cases_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.number_of_confirmed_cases_2.secondary_check.id"
                                @click="
                                    dataObject.number_of_confirmed_cases_2.secondary_check = { id: '' };
                                    dataObject.number_of_confirmed_cases_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.number_of_confirmed_cases_2.secondary_check.id" class="ml-1">{{
                                dataObject.number_of_confirmed_cases_2.secondary_check.name
                                    ? dataObject.number_of_confirmed_cases_2.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">その他条件（別資料）</td>
                    <td colspan="4"><textarea v-model="dataObject.others_conditions.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.others_conditions.first_check && dataObject.others_conditions.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_conditions.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_conditions', 'first_check')"
                            />
                            <span v-if="dataObject.others_conditions.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_conditions.first_check.id"
                                @click="
                                    dataObject.others_conditions.first_check = { id: '' };
                                    dataObject.others_conditions.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_conditions.first_check.id" class="ml-1">{{
                                dataObject.others_conditions.first_check.name ? dataObject.others_conditions.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.others_conditions.secondary_check && dataObject.others_conditions.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.others_conditions.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'others_conditions', 'secondary_check')"
                            />
                            <span v-if="dataObject.others_conditions.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.others_conditions.secondary_check.id"
                                @click="
                                    dataObject.others_conditions.secondary_check = { id: '' };
                                    dataObject.others_conditions.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.others_conditions.secondary_check.id" class="ml-1">{{
                                dataObject.others_conditions.secondary_check.name ? dataObject.others_conditions.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <th colspan="8">＜重複・懸賞チェック＞</th>
                </tr>
                <tr>
                    <td class="bg-color3">重複条件</td>
                    <td class="bg-color3">アカウントID</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.overlapping_conditions_account_id.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlapping_conditions_account_id.first_check &&
                                dataObject.overlapping_conditions_account_id.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlapping_conditions_account_id.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlapping_conditions_account_id', 'first_check')"
                            />
                            <span v-if="dataObject.overlapping_conditions_account_id.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlapping_conditions_account_id.first_check.id"
                                @click="
                                    dataObject.overlapping_conditions_account_id.first_check = { id: '' };
                                    dataObject.overlapping_conditions_account_id.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlapping_conditions_account_id.first_check.id" class="ml-1">{{
                                dataObject.overlapping_conditions_account_id.first_check.name
                                    ? dataObject.overlapping_conditions_account_id.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlapping_conditions_account_id.secondary_check &&
                                dataObject.overlapping_conditions_account_id.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlapping_conditions_account_id.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlapping_conditions_account_id', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlapping_conditions_account_id.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlapping_conditions_account_id.secondary_check.id"
                                @click="
                                    dataObject.overlapping_conditions_account_id.secondary_check = { id: '' };
                                    dataObject.overlapping_conditions_account_id.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlapping_conditions_account_id.secondary_check.id" class="ml-1">{{
                                dataObject.overlapping_conditions_account_id.secondary_check.name
                                    ? dataObject.overlapping_conditions_account_id.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">その他確認項目</td>
                    <td colspan="4"><textarea v-model="dataObject.other_items_to_check.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.other_items_to_check.first_check && dataObject.other_items_to_check.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_items_to_check.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_items_to_check', 'first_check')"
                            />
                            <span v-if="dataObject.other_items_to_check.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_items_to_check.first_check.id"
                                @click="
                                    dataObject.other_items_to_check.first_check = { id: '' };
                                    dataObject.other_items_to_check.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_items_to_check.first_check.id" class="ml-1">{{
                                dataObject.other_items_to_check.first_check.name ? dataObject.other_items_to_check.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.other_items_to_check.secondary_check && dataObject.other_items_to_check.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.other_items_to_check.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_items_to_check', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_items_to_check.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_items_to_check.secondary_check.id"
                                @click="
                                    dataObject.other_items_to_check.secondary_check = { id: '' };
                                    dataObject.other_items_to_check.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_items_to_check.secondary_check.id" class="ml-1">{{
                                dataObject.other_items_to_check.secondary_check.name ? dataObject.other_items_to_check.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="7" class="bg-color3">懸賞確認</td>
                    <td rowspan="2" class="bg-color3">
                        アカウント名<br />
                        指定キーワード有無
                    </td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.account_name.data"
                            :taggable="true"
                            :options="option.account_name"
                            @tag="addTag($event, 'account_name')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.account_name.first_check && dataObject.account_name.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.account_name.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'account_name', 'first_check')"
                            />
                            <span v-if="dataObject.account_name.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.account_name.first_check.id"
                                @click="
                                    dataObject.account_name.first_check = { id: '' };
                                    dataObject.account_name.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.account_name.first_check.id" class="ml-1">{{
                                dataObject.account_name.first_check.name ? dataObject.account_name.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.account_name.secondary_check && dataObject.account_name.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.account_name.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'account_name', 'secondary_check')"
                            />
                            <span v-if="dataObject.account_name.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.account_name.secondary_check.id"
                                @click="
                                    dataObject.account_name.secondary_check = { id: '' };
                                    dataObject.account_name.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.account_name.secondary_check.id" class="ml-1">{{
                                dataObject.account_name.secondary_check.name ? dataObject.account_name.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.specified_keywords.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.specified_keywords.first_check && dataObject.specified_keywords.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_keywords.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_keywords', 'first_check')"
                            />
                            <span v-if="dataObject.specified_keywords.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_keywords.first_check.id"
                                @click="
                                    dataObject.specified_keywords.first_check = { id: '' };
                                    dataObject.specified_keywords.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_keywords.first_check.id" class="ml-1">{{
                                dataObject.specified_keywords.first_check.name ? dataObject.specified_keywords.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.specified_keywords.secondary_check && dataObject.specified_keywords.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_keywords.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'specified_keywords', 'secondary_check')"
                            />
                            <span v-if="dataObject.specified_keywords.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.specified_keywords.secondary_check.id"
                                @click="
                                    dataObject.specified_keywords.secondary_check = { id: '' };
                                    dataObject.specified_keywords.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.specified_keywords.secondary_check.id" class="ml-1">{{
                                dataObject.specified_keywords.secondary_check.name ? dataObject.specified_keywords.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color3">
                        プロフィール文言<br />
                        指定キーワード有無
                    </td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.profile_statement.data"
                            :taggable="true"
                            :options="option.profile_statement"
                            @tag="addTag($event, 'profile_statement')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.profile_statement.first_check && dataObject.profile_statement.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.profile_statement.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'profile_statement', 'first_check')"
                            />
                            <span v-if="dataObject.profile_statement.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.profile_statement.first_check.id"
                                @click="
                                    dataObject.profile_statement.first_check = { id: '' };
                                    dataObject.profile_statement.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.profile_statement.first_check.id" class="ml-1">{{
                                dataObject.profile_statement.first_check.name ? dataObject.profile_statement.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.profile_statement.secondary_check && dataObject.profile_statement.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.profile_statement.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'profile_statement', 'secondary_check')"
                            />
                            <span v-if="dataObject.profile_statement.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.profile_statement.secondary_check.id"
                                @click="
                                    dataObject.profile_statement.secondary_check = { id: '' };
                                    dataObject.profile_statement.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.profile_statement.secondary_check.id" class="ml-1">{{
                                dataObject.profile_statement.secondary_check.name ? dataObject.profile_statement.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.presence_of_specified_keyword.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.presence_of_specified_keyword.first_check && dataObject.presence_of_specified_keyword.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.presence_of_specified_keyword.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'presence_of_specified_keyword', 'first_check')"
                            />
                            <span v-if="dataObject.presence_of_specified_keyword.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.presence_of_specified_keyword.first_check.id"
                                @click="
                                    dataObject.presence_of_specified_keyword.first_check = { id: '' };
                                    dataObject.presence_of_specified_keyword.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.presence_of_specified_keyword.first_check.id" class="ml-1">{{
                                dataObject.presence_of_specified_keyword.first_check.name
                                    ? dataObject.presence_of_specified_keyword.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.presence_of_specified_keyword.secondary_check &&
                                dataObject.presence_of_specified_keyword.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.presence_of_specified_keyword.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'presence_of_specified_keyword', 'secondary_check')"
                            />
                            <span v-if="dataObject.presence_of_specified_keyword.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.presence_of_specified_keyword.secondary_check.id"
                                @click="
                                    dataObject.presence_of_specified_keyword.secondary_check = { id: '' };
                                    dataObject.presence_of_specified_keyword.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.presence_of_specified_keyword.secondary_check.id" class="ml-1">{{
                                dataObject.presence_of_specified_keyword.secondary_check.name
                                    ? dataObject.presence_of_specified_keyword.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="3" class="bg-color3">直近投稿内容確認</td>
                    <td colspan="4"><textarea v-model="dataObject.check_the_latest_posts_1.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_1.first_check && dataObject.check_the_latest_posts_1.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_1', 'first_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_1.first_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_1.first_check = { id: '' };
                                    dataObject.check_the_latest_posts_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_1.first_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_1.first_check.name ? dataObject.check_the_latest_posts_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_1.secondary_check && dataObject.check_the_latest_posts_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_1.secondary_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_1.secondary_check = { id: '' };
                                    dataObject.check_the_latest_posts_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_1.secondary_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_1.secondary_check.name
                                    ? dataObject.check_the_latest_posts_1.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.check_the_latest_posts_2.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_2.first_check && dataObject.check_the_latest_posts_2.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_2', 'first_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_2.first_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_2.first_check = { id: '' };
                                    dataObject.check_the_latest_posts_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_2.first_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_2.first_check.name ? dataObject.check_the_latest_posts_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_2.secondary_check && dataObject.check_the_latest_posts_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_2.secondary_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_2.secondary_check = { id: '' };
                                    dataObject.check_the_latest_posts_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_2.secondary_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_2.secondary_check.name
                                    ? dataObject.check_the_latest_posts_2.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><textarea v-model="dataObject.check_the_latest_posts_3.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_3.first_check && dataObject.check_the_latest_posts_3.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_3.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_3', 'first_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_3.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_3.first_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_3.first_check = { id: '' };
                                    dataObject.check_the_latest_posts_3.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_3.first_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_3.first_check.name ? dataObject.check_the_latest_posts_3.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.check_the_latest_posts_3.secondary_check && dataObject.check_the_latest_posts_3.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_3.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'check_the_latest_posts_3', 'secondary_check')"
                            />
                            <span v-if="dataObject.check_the_latest_posts_3.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.check_the_latest_posts_3.secondary_check.id"
                                @click="
                                    dataObject.check_the_latest_posts_3.secondary_check = { id: '' };
                                    dataObject.check_the_latest_posts_3.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.check_the_latest_posts_3.secondary_check.id" class="ml-1">{{
                                dataObject.check_the_latest_posts_3.secondary_check.name
                                    ? dataObject.check_the_latest_posts_3.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">その他条件（別資料）</td>
                    <td colspan="4"><textarea v-model="dataObject.other_condition_prize_check.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.other_condition_prize_check.first_check && dataObject.other_condition_prize_check.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_prize_check.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_condition_prize_check', 'first_check')"
                            />
                            <span v-if="dataObject.other_condition_prize_check.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_prize_check.first_check.id"
                                @click="
                                    dataObject.other_condition_prize_check.first_check = { id: '' };
                                    dataObject.other_condition_prize_check.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_condition_prize_check.first_check.id" class="ml-1">{{
                                dataObject.other_condition_prize_check.first_check.name ? dataObject.other_condition_prize_check.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.other_condition_prize_check.secondary_check &&
                                dataObject.other_condition_prize_check.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_prize_check.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_condition_prize_check', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_condition_prize_check.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_condition_prize_check.secondary_check.id"
                                @click="
                                    dataObject.other_condition_prize_check.secondary_check = { id: '' };
                                    dataObject.other_condition_prize_check.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_condition_prize_check.secondary_check.id" class="ml-1">{{
                                dataObject.other_condition_prize_check.secondary_check.name
                                    ? dataObject.other_condition_prize_check.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <template v-if="checkOrder">
                    <tr>
                        <th colspan="8">↓↓当選連絡後</th>
                    </tr>
                    <tr>
                        <th colspan="8">＜条件チェック＞</th>
                    </tr>
                    <tr>
                        <td colspan="2" class="bg-color3">対象ファイル</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.target_file_status_check.data"
                                :taggable="true"
                                :options="option.target_file_status_check"
                                @tag="addTag($event, 'target_file_status_check')"
                            ></multiselect>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="dataObject.target_file_status_check.first_check && dataObject.target_file_status_check.first_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.target_file_status_check.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'target_file_status_check', 'first_check')"
                                />
                                <span v-if="dataObject.target_file_status_check.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.target_file_status_check.first_check.id"
                                    @click="
                                        dataObject.target_file_status_check.first_check = { id: '' };
                                        dataObject.target_file_status_check.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.target_file_status_check.first_check.id" class="ml-1">{{
                                    dataObject.target_file_status_check.first_check.name ? dataObject.target_file_status_check.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.target_file_status_check.secondary_check && dataObject.target_file_status_check.secondary_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.target_file_status_check.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'target_file_status_check', 'secondary_check')"
                                />
                                <span v-if="dataObject.target_file_status_check.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.target_file_status_check.secondary_check.id"
                                    @click="
                                        dataObject.target_file_status_check.secondary_check = { id: '' };
                                        dataObject.target_file_status_check.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.target_file_status_check.secondary_check.id" class="ml-1">{{
                                    dataObject.target_file_status_check.secondary_check.name
                                        ? dataObject.target_file_status_check.secondary_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="bg-color3">
                            期間条件（抽出対象期間）<br />
                            ※データのDLは全期間で実施してデータ上で抽出
                        </td>
                        <td colspan="4">
                            {{ renderDate1(detailBeginForDocument.campaign_start_date) }}～{{ renderDate1(detailBeginForDocument.campaign_end_date) }}
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="dataObject.period_conditions_extracted.first_check && dataObject.period_conditions_extracted.first_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.period_conditions_extracted.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'period_conditions_extracted', 'first_check')"
                                />
                                <span v-if="dataObject.period_conditions_extracted.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.period_conditions_extracted.first_check.id"
                                    @click="
                                        dataObject.period_conditions_extracted.first_check = { id: '' };
                                        dataObject.period_conditions_extracted.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.period_conditions_extracted.first_check.id" class="ml-1">{{
                                    dataObject.period_conditions_extracted.first_check.name
                                        ? dataObject.period_conditions_extracted.first_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.period_conditions_extracted.secondary_check &&
                                    dataObject.period_conditions_extracted.secondary_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.period_conditions_extracted.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'period_conditions_extracted', 'secondary_check')"
                                />
                                <span v-if="dataObject.period_conditions_extracted.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.period_conditions_extracted.secondary_check.id"
                                    @click="
                                        dataObject.period_conditions_extracted.secondary_check = { id: '' };
                                        dataObject.period_conditions_extracted.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.period_conditions_extracted.secondary_check.id" class="ml-1">{{
                                    dataObject.period_conditions_extracted.secondary_check.name
                                        ? dataObject.period_conditions_extracted.secondary_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" rowspan="2" class="bg-color3">最終納品データの形指定有無</td>
                        <td colspan="4">
                            <multiselect
                                :multiple="true"
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                v-model="dataObject.specifies_the_data_format.data"
                                :taggable="true"
                                :options="option.specifies_the_data_format"
                                @tag="addTag($event, 'specifies_the_data_format')"
                            ></multiselect>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="dataObject.specifies_the_data_format.first_check && dataObject.specifies_the_data_format.first_check.change"
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'specifies_the_data_format', 'first_check')"
                                />
                                <span v-if="dataObject.specifies_the_data_format.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format.first_check.id"
                                    @click="
                                        dataObject.specifies_the_data_format.first_check = { id: '' };
                                        dataObject.specifies_the_data_format.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.specifies_the_data_format.first_check.id" class="ml-1">{{
                                    dataObject.specifies_the_data_format.first_check.name ? dataObject.specifies_the_data_format.first_check.name : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.specifies_the_data_format.secondary_check &&
                                    dataObject.specifies_the_data_format.secondary_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'specifies_the_data_format', 'secondary_check')"
                                />
                                <span v-if="dataObject.specifies_the_data_format.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format.secondary_check.id"
                                    @click="
                                        dataObject.specifies_the_data_format.secondary_check = { id: '' };
                                        dataObject.specifies_the_data_format.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.specifies_the_data_format.secondary_check.id" class="ml-1">{{
                                    dataObject.specifies_the_data_format.secondary_check.name
                                        ? dataObject.specifies_the_data_format.secondary_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <textarea v-model="dataObject.specifies_the_data_format_detail.data" class="form-control" />
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.specifies_the_data_format_detail.first_check &&
                                    dataObject.specifies_the_data_format_detail.first_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format_detail.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'specifies_the_data_format_detail', 'first_check')"
                                />
                                <span v-if="dataObject.specifies_the_data_format_detail.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format_detail.first_check.id"
                                    @click="
                                        dataObject.specifies_the_data_format_detail.first_check = { id: '' };
                                        dataObject.specifies_the_data_format_detail.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.specifies_the_data_format_detail.first_check.id" class="ml-1">{{
                                    dataObject.specifies_the_data_format_detail.first_check.name
                                        ? dataObject.specifies_the_data_format_detail.first_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.specifies_the_data_format_detail.secondary_check &&
                                    dataObject.specifies_the_data_format_detail.secondary_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format_detail.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'specifies_the_data_format_detail', 'secondary_check')"
                                />
                                <span v-if="dataObject.specifies_the_data_format_detail.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.specifies_the_data_format_detail.secondary_check.id"
                                    @click="
                                        dataObject.specifies_the_data_format_detail.secondary_check = { id: '' };
                                        dataObject.specifies_the_data_format_detail.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.specifies_the_data_format_detail.secondary_check.id" class="ml-1">{{
                                    dataObject.specifies_the_data_format_detail.secondary_check.name
                                        ? dataObject.specifies_the_data_format_detail.secondary_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="bg-color3">その他条件</td>
                        <td colspan="4">
                            <textarea v-model="dataObject.other_conditions_status_check.data" class="form-control" />
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.other_conditions_status_check.first_check &&
                                    dataObject.other_conditions_status_check.first_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.other_conditions_status_check.first_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'other_conditions_status_check', 'first_check')"
                                />
                                <span v-if="dataObject.other_conditions_status_check.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.other_conditions_status_check.first_check.id"
                                    @click="
                                        dataObject.other_conditions_status_check.first_check = { id: '' };
                                        dataObject.other_conditions_status_check.first_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.other_conditions_status_check.first_check.id" class="ml-1">{{
                                    dataObject.other_conditions_status_check.first_check.name
                                        ? dataObject.other_conditions_status_check.first_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                        <td>
                            <label
                                for=""
                                v-if="
                                    dataObject.other_conditions_status_check.secondary_check &&
                                    dataObject.other_conditions_status_check.secondary_check.change
                                "
                            >
                                <input
                                    type="checkbox"
                                    :checked="dataObject.other_conditions_status_check.secondary_check.id === currentUser.id"
                                    :value="currentUser.id"
                                    @change="updateCheckbox($event, currentUser.id, 'other_conditions_status_check', 'secondary_check')"
                                />
                                <span v-if="dataObject.other_conditions_status_check.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                            </label>
                            <label for="" v-else>
                                <input
                                    type="checkbox"
                                    :checked="dataObject.other_conditions_status_check.secondary_check.id"
                                    @click="
                                        dataObject.other_conditions_status_check.secondary_check = { id: '' };
                                        dataObject.other_conditions_status_check.secondary_check.change = true;
                                    "
                                />
                                <span v-if="dataObject.other_conditions_status_check.secondary_check.id" class="ml-1">{{
                                    dataObject.other_conditions_status_check.secondary_check.name
                                        ? dataObject.other_conditions_status_check.secondary_check.name
                                        : ''
                                }}</span>
                            </label>
                        </td>
                    </tr>
                </template>

                <tr>
                    <th colspan="8">＜重複・照合チェック＞</th>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">照合項目</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.matching_items.data"
                            :taggable="true"
                            :options="option.matching_items"
                            @tag="addTag($event, 'matching_items')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.matching_items.first_check && dataObject.matching_items.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_items.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_items', 'first_check')"
                            />
                            <span v-if="dataObject.matching_items.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_items.first_check.id"
                                @click="
                                    dataObject.matching_items.first_check = { id: '' };
                                    dataObject.matching_items.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_items.first_check.id" class="ml-1">{{
                                dataObject.matching_items.first_check.name ? dataObject.matching_items.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.matching_items.secondary_check && dataObject.matching_items.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_items.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'matching_items', 'secondary_check')"
                            />
                            <span v-if="dataObject.matching_items.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.matching_items.secondary_check.id"
                                @click="
                                    dataObject.matching_items.secondary_check = { id: '' };
                                    dataObject.matching_items.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.matching_items.secondary_check.id" class="ml-1">{{
                                dataObject.matching_items.secondary_check.name ? dataObject.matching_items.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color3">照合条件</td>
                    <td rowspan="2" class="bg-color3">ID</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.condition_maching.data"
                            :taggable="true"
                            :options="option.condition_maching"
                            @tag="addTag($event, 'condition_maching')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.condition_maching.first_check && dataObject.condition_maching.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'condition_maching', 'first_check')"
                            />
                            <span v-if="dataObject.condition_maching.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching.first_check.id"
                                @click="
                                    dataObject.condition_maching.first_check = { id: '' };
                                    dataObject.condition_maching.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.condition_maching.first_check.id" class="ml-1">{{
                                dataObject.condition_maching.first_check.name ? dataObject.condition_maching.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.condition_maching.secondary_check && dataObject.condition_maching.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'condition_maching', 'secondary_check')"
                            />
                            <span v-if="dataObject.condition_maching.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching.secondary_check.id"
                                @click="
                                    dataObject.condition_maching.secondary_check = { id: '' };
                                    dataObject.condition_maching.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.condition_maching.secondary_check.id" class="ml-1">{{
                                dataObject.condition_maching.secondary_check.name ? dataObject.condition_maching.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"><textarea v-model="dataObject.condition_maching_data.data.text1" class="form-control" /></td>
                    <td colspan="2">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.condition_maching_data.data.text2"
                            :taggable="true"
                            :options="option.condition_maching_data"
                            @tag="addTag($event, 'condition_maching_data')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.condition_maching_data.first_check && dataObject.condition_maching_data.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching_data.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'condition_maching_data', 'first_check')"
                            />
                            <span v-if="dataObject.condition_maching_data.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching_data.first_check.id"
                                @click="
                                    dataObject.condition_maching_data.first_check = { id: '' };
                                    dataObject.condition_maching_data.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.condition_maching_data.first_check.id" class="ml-1">{{
                                dataObject.condition_maching_data.first_check.name ? dataObject.condition_maching_data.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.condition_maching_data.secondary_check && dataObject.condition_maching_data.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching_data.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'condition_maching_data', 'secondary_check')"
                            />
                            <span v-if="dataObject.condition_maching_data.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.condition_maching_data.secondary_check.id"
                                @click="
                                    dataObject.condition_maching_data.secondary_check = { id: '' };
                                    dataObject.condition_maching_data.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.condition_maching_data.secondary_check.id" class="ml-1">{{
                                dataObject.condition_maching_data.secondary_check.name ? dataObject.condition_maching_data.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="6" class="bg-color3">重複条件</td>
                    <td rowspan="2" class="bg-color3">指定ID</td>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.overlap_condition_id_1.data"
                            :taggable="true"
                            :options="option.overlap_condition_id_1"
                            @tag="addTag($event, 'overlap_condition_id_1')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.overlap_condition_id_1.first_check && dataObject.overlap_condition_id_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_id_1', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_id_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_1.first_check.id"
                                @click="
                                    dataObject.overlap_condition_id_1.first_check = { id: '' };
                                    dataObject.overlap_condition_id_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_id_1.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_id_1.first_check.name ? dataObject.overlap_condition_id_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.overlap_condition_id_1.secondary_check && dataObject.overlap_condition_id_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_id_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_id_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_1.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_id_1.secondary_check = { id: '' };
                                    dataObject.overlap_condition_id_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_id_1.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_id_1.secondary_check.name ? dataObject.overlap_condition_id_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.overlap_condition_id_2.data"
                            :taggable="true"
                            :options="option.overlap_condition_id_2"
                            @tag="addTag($event, 'overlap_condition_id_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.overlap_condition_id_2.first_check && dataObject.overlap_condition_id_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_id_2', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_id_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_2.first_check.id"
                                @click="
                                    dataObject.overlap_condition_id_2.first_check = { id: '' };
                                    dataObject.overlap_condition_id_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_id_2.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_id_2.first_check.name ? dataObject.overlap_condition_id_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.overlap_condition_id_2.secondary_check && dataObject.overlap_condition_id_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_id_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_id_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_id_2.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_id_2.secondary_check = { id: '' };
                                    dataObject.overlap_condition_id_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_id_2.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_id_2.secondary_check.name ? dataObject.overlap_condition_id_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>

                <tr>
                    <td rowspan="2" class="bg-color3">同一世帯</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.overlap_condition_same_house_1.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_house_1.first_check && dataObject.overlap_condition_same_house_1.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_house_1', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_house_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_1.first_check.id"
                                @click="
                                    dataObject.overlap_condition_same_house_1.first_check = { id: '' };
                                    dataObject.overlap_condition_same_house_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_house_1.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_house_1.first_check.name
                                    ? dataObject.overlap_condition_same_house_1.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_house_1.secondary_check &&
                                dataObject.overlap_condition_same_house_1.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_house_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_house_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_1.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_same_house_1.secondary_check = { id: '' };
                                    dataObject.overlap_condition_same_house_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_house_1.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_house_1.secondary_check.name
                                    ? dataObject.overlap_condition_same_house_1.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.overlap_condition_same_house_2.data"
                            :taggable="true"
                            :options="option.overlap_condition_same_house_2"
                            @tag="addTag($event, 'overlap_condition_same_house_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_house_2.first_check && dataObject.overlap_condition_same_house_2.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_house_2', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_house_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_2.first_check.id"
                                @click="
                                    dataObject.overlap_condition_same_house_2.first_check = { id: '' };
                                    dataObject.overlap_condition_same_house_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_house_2.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_house_2.first_check.name
                                    ? dataObject.overlap_condition_same_house_2.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_house_2.secondary_check &&
                                dataObject.overlap_condition_same_house_2.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_house_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_house_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_house_2.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_same_house_2.secondary_check = { id: '' };
                                    dataObject.overlap_condition_same_house_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_house_2.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_house_2.secondary_check.name
                                    ? dataObject.overlap_condition_same_house_2.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" class="bg-color3">同一人物</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.overlap_condition_same_person_1.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_person_1.first_check &&
                                dataObject.overlap_condition_same_person_1.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_person_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_person_1', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_person_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_person_1.first_check.id"
                                @click="
                                    dataObject.overlap_condition_same_person_1.first_check = { id: '' };
                                    dataObject.overlap_condition_same_person_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_person_1.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_person_1.first_check.name
                                    ? dataObject.overlap_condition_same_person_1.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_person_1.secondary_check &&
                                dataObject.overlap_condition_same_person_1.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_person_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_person_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_person_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_person_1.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_same_person_1.secondary_check = { id: '' };
                                    dataObject.overlap_condition_same_person_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_person_1.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_person_1.secondary_check.name
                                    ? dataObject.overlap_condition_same_person_1.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.overlap_condition_same_preson_2.data"
                            :taggable="true"
                            :options="option.overlap_condition_same_preson_2"
                            @tag="addTag($event, 'overlap_condition_same_preson_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_preson_2.first_check &&
                                dataObject.overlap_condition_same_preson_2.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_preson_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_preson_2', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_preson_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_preson_2.first_check.id"
                                @click="
                                    dataObject.overlap_condition_same_preson_2.first_check = { id: '' };
                                    dataObject.overlap_condition_same_preson_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_preson_2.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_preson_2.first_check.name
                                    ? dataObject.overlap_condition_same_preson_2.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.overlap_condition_same_preson_2.secondary_check &&
                                dataObject.overlap_condition_same_preson_2.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_preson_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_same_preson_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_same_preson_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_same_preson_2.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_same_preson_2.secondary_check = { id: '' };
                                    dataObject.overlap_condition_same_preson_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_same_preson_2.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_same_preson_2.secondary_check.name
                                    ? dataObject.overlap_condition_same_preson_2.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">その他条件</td>
                    <td colspan="4"><textarea v-model="dataObject.overlap_condition_others.data" class="form-control" /></td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.overlap_condition_others.first_check && dataObject.overlap_condition_others.first_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_others.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_others', 'first_check')"
                            />
                            <span v-if="dataObject.overlap_condition_others.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_others.first_check.id"
                                @click="
                                    dataObject.overlap_condition_others.first_check = { id: '' };
                                    dataObject.overlap_condition_others.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_others.first_check.id" class="ml-1">{{
                                dataObject.overlap_condition_others.first_check.name ? dataObject.overlap_condition_others.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.overlap_condition_others.secondary_check && dataObject.overlap_condition_others.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_others.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'overlap_condition_others', 'secondary_check')"
                            />
                            <span v-if="dataObject.overlap_condition_others.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.overlap_condition_others.secondary_check.id"
                                @click="
                                    dataObject.overlap_condition_others.secondary_check = { id: '' };
                                    dataObject.overlap_condition_others.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.overlap_condition_others.secondary_check.id" class="ml-1">{{
                                dataObject.overlap_condition_others.secondary_check.name
                                    ? dataObject.overlap_condition_others.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <th colspan="8">＜不備チェック＞</th>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">名前不備確認</td>
                    <td colspan="4"><textarea v-model="dataObject.confirm_error_name.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.first_check && dataObject.confirm_error_name.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.first_check.id"
                                @click="
                                    dataObject.confirm_error_name.first_check = { id: '' };
                                    dataObject.confirm_error_name.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.first_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.first_check.name ? dataObject.confirm_error_name.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.confirm_error_name.secondary_check && dataObject.confirm_error_name.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_error_name', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_error_name.secondary_check.id"
                                @click="
                                    dataObject.confirm_error_name.secondary_check = { id: '' };
                                    dataObject.confirm_error_name.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_error_name.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_error_name.secondary_check.name ? dataObject.confirm_error_name.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" rowspan="2" class="bg-color3">住所不備確認</td>
                    <td colspan="4"><textarea v-model="dataObject.address_verification_1.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_1.first_check && dataObject.address_verification_1.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.first_check.id"
                                @click="
                                    dataObject.address_verification_1.first_check = { id: '' };
                                    dataObject.address_verification_1.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.first_check.id" class="ml-1">{{
                                dataObject.address_verification_1.first_check.name ? dataObject.address_verification_1.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_1.secondary_check && dataObject.address_verification_1.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_1', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_1.secondary_check.id"
                                @click="
                                    dataObject.address_verification_1.secondary_check = { id: '' };
                                    dataObject.address_verification_1.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_1.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_1.secondary_check.name ? dataObject.address_verification_1.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <multiselect
                            :multiple="true"
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            v-model="dataObject.address_verification_2.data"
                            :taggable="true"
                            :options="option.address_verification_2"
                            @tag="addTag($event, 'address_verification_2')"
                        ></multiselect>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.address_verification_2.first_check && dataObject.address_verification_2.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'first_check')"
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.first_check.id"
                                @click="
                                    dataObject.address_verification_2.first_check = { id: '' };
                                    dataObject.address_verification_2.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.first_check.id" class="ml-1">{{
                                dataObject.address_verification_2.first_check.name ? dataObject.address_verification_2.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="dataObject.address_verification_2.secondary_check && dataObject.address_verification_2.secondary_check.change"
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'address_verification_2', 'secondary_check')"
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.address_verification_2.secondary_check.id"
                                @click="
                                    dataObject.address_verification_2.secondary_check = { id: '' };
                                    dataObject.address_verification_2.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.address_verification_2.secondary_check.id" class="ml-1">{{
                                dataObject.address_verification_2.secondary_check.name ? dataObject.address_verification_2.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">電話番号不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_phone_number_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.first_check &&
                                dataObject.confirm_phone_number_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_phone_number_is_incomplete.secondary_check &&
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_phone_number_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_phone_number_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_phone_number_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_phone_number_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_phone_number_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">メールアドレス不備確認</td>
                    <td colspan="4">
                        <textarea v-model="dataObject.confirm_email_address_is_incomplete.data" class="form-control" />
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.first_check &&
                                dataObject.confirm_email_address_is_incomplete.first_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'first_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.first_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.first_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.first_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.first_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.first_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label
                            for=""
                            v-if="
                                dataObject.confirm_email_address_is_incomplete.secondary_check &&
                                dataObject.confirm_email_address_is_incomplete.secondary_check.change
                            "
                        >
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'confirm_email_address_is_incomplete', 'secondary_check')"
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.confirm_email_address_is_incomplete.secondary_check.id"
                                @click="
                                    dataObject.confirm_email_address_is_incomplete.secondary_check = { id: '' };
                                    dataObject.confirm_email_address_is_incomplete.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.confirm_email_address_is_incomplete.secondary_check.id" class="ml-1">{{
                                dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    ? dataObject.confirm_email_address_is_incomplete.secondary_check.name
                                    : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="bg-color3">その他</td>
                    <td colspan="4"><textarea v-model="dataObject.other_errors.data" class="form-control" /></td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.first_check && dataObject.other_errors.first_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'first_check')"
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.first_check.id"
                                @click="
                                    dataObject.other_errors.first_check = { id: '' };
                                    dataObject.other_errors.first_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.first_check.id" class="ml-1">{{
                                dataObject.other_errors.first_check.name ? dataObject.other_errors.first_check.name : ''
                            }}</span>
                        </label>
                    </td>
                    <td>
                        <label for="" v-if="dataObject.other_errors.secondary_check && dataObject.other_errors.secondary_check.change">
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id === currentUser.id"
                                :value="currentUser.id"
                                @change="updateCheckbox($event, currentUser.id, 'other_errors', 'secondary_check')"
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{ currentUser.name }}</span>
                        </label>
                        <label for="" v-else>
                            <input
                                type="checkbox"
                                :checked="dataObject.other_errors.secondary_check.id"
                                @click="
                                    dataObject.other_errors.secondary_check = { id: '' };
                                    dataObject.other_errors.secondary_check.change = true;
                                "
                            />
                            <span v-if="dataObject.other_errors.secondary_check.id" class="ml-1">{{
                                dataObject.other_errors.secondary_check.name ? dataObject.other_errors.secondary_check.name : ''
                            }}</span>
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'Tab8Require',
    props: {
        checkOrder: {
            type: Boolean,
            required: false
        },
        dataObject: {
            type: Object,
            required: false
        },
        currentUser: {
            type: Object,
            required: false
        },
        detailBeginForDocument: {
            type: Object,
            required: false
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            customFormatDate: 'YYYY-MM-DD',
            option: {
                official_account_follow_check: ['なし', 'あり(データで確認可能)', 'あり(目チェック)'],
                target_file: ['キャンつく', 'ATELU', '支給データ'],
                number_of_followers: ['あり　データ確認', 'あり　目視確認'],
                number_of_posts: ['あり　データ確認', 'あり　目視確認'],
                account_name: ['なし', 'あり(データで確認可能)', 'あり(目チェック)'],
                profile_statement: ['なし', 'あり(データで確認可能)', 'あり(目チェック)'],
                target_file_status_check: ['エイジアフォーム', 'その他フォーム', '支給データ'],
                specifies_the_data_format: ['あり', 'なし'],
                matching_items: ['アカウントID', '当選ID', 'なし'],
                condition_maching: ['なし', 'あり(フォーム照合機能あり)', 'あり(フォーム照合機能なし)'],
                condition_maching_data: ['完全一致', '近似値目チェック'],
                overlap_condition_id_1: ['指定ID', '●●'],
                overlap_condition_id_2: ['完全一致', '近似値目チェック'],
                overlap_condition_same_house_2: ['完全一致', '近似値目チェック'],
                overlap_condition_same_preson_2: ['完全一致', '近似値目チェック'],
                address_verification_2: ['システムチェック', '近似値目チェック']
            }
        };
    },
    methods: {
        renderDate(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                return `${year} 年 ${month} 月 ${day} 日`;
            }
            return '';
        },
        renderDate1(time) {
            const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];
            const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = addLeadingZero(date.getHours());
                const minutes = addLeadingZero(date.getMinutes());
                const dayOfWeek = date.getDay();
                const dayNameInJapanese = daysInJapanese[dayOfWeek];
                return `${year} 年 ${month} 月 ${day} 日（${dayNameInJapanese}）${hours}：${minutes}`;
            }
            return '';
        },
        addTag(newTag, key) {
            console.log(key);
            this.option[key].push(newTag);
            this.dataObject[key].data.push(newTag);
        },
        updateCheckbox(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject[key][key2].id = value;
            } else {
                this.dataObject[key][key2].id = null;
            }
        },
        updateCheckbox1(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject.hashtags.data[key][key2].id = value;
            } else {
                this.dataObject.hashtags.data[key][key2].id = null;
            }
        },
        updateCheckbox2(event, value, key, key2) {
            if (event.target.checked) {
                this.dataObject.twitter_rt.data[key][key2].id = value;
            } else {
                this.dataObject.twitter_rt.data[key][key2].id = null;
            }
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
