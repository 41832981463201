import i18n from '@/i18n';

const CONFIG_TABLE = {
    headers: [
        { label: 'No.', required: false, width: 'max-content', bgr: '#f2f0f0' },
        { label: i18n.t('pageCampaign.tabPrize.titlePrize'), required: true, width: '250px', bgr: '#c9daf8' },
        { label: i18n.t('pageCampaign.tabPrize.type'), required: false, width: '70px', bgr: '#c9daf8' },
        {
            label: i18n.t('pageCampaign.tabPrize.numberOfWinners'),
            required: false,
            width: '70px',
            bgr: '#c9daf8'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.numberOfPeopleDrawn'),
            required: false,
            width: '70px',
            bgr: '#c9daf8'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.scheduledShipping/presentationDate'),
            required: false,
            width: 'max-content',
            bgr: '#c9daf8'
        }, // ngày dự định theo lịch, giao hàng
        {
            label: i18n.t('pageCampaign.tabPrize.shippingDate/PresentDate'),
            required: false,
            width: 'max-content',
            bgr: '#d9ead3'
        }, // ngày dự định, giao hàng
        { label: i18n.t('pageCampaign.tabPrize.numberOfShipments'), required: false, width: '70px', bgr: '#d9ead3' }, // số lượng giao hàng
        {
            label: i18n.t('pageCampaign.tabPrize.returnDetails'),
            required: false,
            width: 'max-content',
            bgr: '#d9d9d9'
        }, // chi tiết trả về
        { label: i18n.t('pageCampaign.tabPrize.numberOfReturns'), required: false, width: '70px', bgr: '#d9ead3' }, // số phần thưởng trả về
        { label: '', required: false, width: 'max-content', bgr: '#d9d9d9' },
        { label: '', required: false, width: 'max-content', bgr: '#d9d9d9' }
    ],
    headersChild: [
        { label: '', required: false, width: 'max-content', bgr: '#ffff' },
        { label: i18n.t('pageCampaign.tabPrize.namePrize'), required: true, width: '250px', bgr: '#c9daf8' },
        { label: i18n.t('pageCampaign.tabPrize.quantityEachPrize'), required: false, width: '70px', bgr: '#c9daf8' },
        {
            label: i18n.t('pageCampaign.tabPrize.requiredNumberOfPrizes'),
            required: false,
            width: '70px',
            bgr: '#c9daf8'
        }, //số lượng phần thưởng cần thiết
        {
            label: i18n.t('pageCampaign.tabPrize.scheduledArrivalDate'),
            required: false,
            width: 'max-content',
            bgr: '#c9daf8'
        }, // ngày dự định nhập kho
        {
            label: i18n.t('pageCampaign.tabPrize.receiptDate'),
            required: false,
            width: 'max-content',
            bgr: '#d9ead3'
        }, // ngày nhập kho
        {
            label: i18n.t('pageCampaign.tabPrize.numberOfItemsInStock'),
            required: false,
            width: '70px',
            bgr: '#d9ead3'
        }, // số lượng nhập kho
        {
            label: i18n.t('pageCampaign.tabPrize.remainingNumberOfPlans'),
            required: false,
            width: '70px',
            bgr: '#d9d9d9'
        }, // số lượng dự định còn
        {
            label: i18n.t('pageCampaign.tabPrize.memo'),
            required: false,
            width: 'max-content',
            bgr: '#d9d9d9'
        }, // memo (mục liên lạc)
        {
            label: i18n.t('pageCampaign.tabPrize.detailedInformation'),
            required: false,
            width: 'max-content',
            bgr: '#d9d9d9'
        }, // thông tin chi tiết
        { label: i18n.t('pageCampaign.tabPrize.actuallyTooMany'), required: false, width: '70px', bgr: '#d9ead3' }, // số lượng còn lại thực tế
        {
            label: i18n.t('pageCampaign.tabPrize.notVeryCompatible'),
            required: false,
            width: 'max-content',
            bgr: '#d9d9d9'
        } // đối ứng còn thừa
        // { label: '', required: false, width: 'max-content', bgr: '#d9d9d9' }
    ]
};

const CONFIG_TABLE_DETAIL_RETURN = {
    headers: [
        { label: i18n.t('pageCampaign.tabPrize.returnDate'), required: true, width: '250px' },
        { label: i18n.t('pageCampaign.tabPrize.shippingCompanyReturn'), required: false, width: '70px' },
        {
            label: i18n.t('pageCampaign.tabPrize.trackingNumber'),
            required: false,
            width: '70px'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.reasonForReturn'),
            required: true,
            width: '70px'
        },
        { label: i18n.t('pageCampaign.tabPrize.prefectures'), required: false, width: '250px' },
        { label: i18n.t('pageCampaign.tabPrize.returnShippingFee'), required: false, width: '70px' },
        {
            label: i18n.t('pageCampaign.tabPrize.returnStatus'),
            required: true,
            width: '70px'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.reconfirmationDate'),
            required: false,
            width: 'max-content'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.instructionsForReturningPrizes'),
            required: false,
            width: 'max-content',
            bgr: '#d9ead3'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.locatorNumber'),
            required: false,
            width: '70px',
            bgr: '#d9ead3'
        },
        {
            label: i18n.t('pageCampaign.tabPrize.remarks'),
            required: false,
            width: '70px',
            bgr: '#d9d9d9'
        },
        { label: '', required: false, width: 'max-content', bgr: '#d9d9d9' }
    ]
};

const CONFIG_INFOMATION = {
    id: '',
    title: '',
    isShow: false,
    dataInfo: {},
    dataInfoCopy: {}
};

const CONFIG_COMPATIBLE = {
    id: '',
    title: '',
    isShow: false,
    dataCompatible: {}
};

const CONFIG_MODALCOMMONINFO = {
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg'
};

const CONFIG_MODALCOMPATIBLE = {
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg'
};

const CONFIG_MODAL_DETAIL_RETURN = {
    title: '',
    size: '4xl',
    model: false
};

export {
    CONFIG_TABLE,
    CONFIG_INFOMATION,
    CONFIG_COMPATIBLE,
    CONFIG_MODALCOMMONINFO,
    CONFIG_MODALCOMPATIBLE,
    CONFIG_TABLE_DETAIL_RETURN,
    CONFIG_MODAL_DETAIL_RETURN
};
