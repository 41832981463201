function funcSetCondition1(listCategoriesSub, listCategories, listDataMasterCampaignTypeChild, form) {
    // listCategoriesSub = []
    let filteredObjects = listCategoriesSub.filter((item) => !listCategories.includes(item.category_id));
    if (filteredObjects.length > 0) {
        filteredObjects.forEach((item) => {
            if (item.category_id == 82) {
                // WEB clear
                form.application_form = '';
            }

            let index = listCategoriesSub.findIndex((x) => {
                return x.category_id == item.category_id;
            });

            if (index >= 0) {
                listCategoriesSub.splice(index, 1);
            }
        });
    } else {
        listCategories.forEach((item) => {
            let index = listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == item;
            });
            let check = listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == item && (x.value == 'Twitter' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'Tiktok');
            });
            let i = listCategoriesSub.findIndex((x) => {
                return x.category_id == item;
            });
            if (index >= 0 && i < 0) {
                let obj = {
                    category_id: item,
                    category_value: listDataMasterCampaignTypeChild.find((typeName) => typeName.id == item)?.value,
                    application_condition_1: null,
                    application_condition_2_id: [],
                    listCondition1: listDataMasterCampaignTypeChild[index].data,
                    listCondition2: [],
                    extension: {
                        offical_name_sns: '',
                        offical_id_sns: '',
                        application_method: '',
                        content_other: ''
                    },
                    checkSNS: check
                };
                listCategoriesSub.push(obj);
                listCategoriesSub = listCategoriesSub.sort((subA, subB) => subA.category_id - subB.category_id);
            }
        });
    }

    return {
        listCategoriesSub,
        listCategories,
        listDataMasterCampaignTypeChild,
        form
    };
}

function funcOrderLv1(
    itemLv1,
    listChecked,
    listOrderFilterLv1,
    listOrderFilterLv2,
    listOrderFilterLv3,
    listOrderFilterLv4,
    listRadio,
    contact_informations
) {
    if (listChecked.msOrderLv1.includes(itemLv1.id)) {
        listOrderFilterLv1.push(itemLv1);
        listOrderFilterLv1 = listOrderFilterLv1.sort((aLv1, bLv1) => aLv1.id - bLv1.id);
    } else {
        listOrderFilterLv1 = listOrderFilterLv1.filter((item) => item.id !== itemLv1.id);
        itemLv1.data.forEach((lv1) => {
            listChecked.msOrderLv2 = listChecked.msOrderLv2.filter((itemLv2) => lv1.id !== itemLv2);
            listOrderFilterLv2 = listOrderFilterLv2.filter((orderFl2) => orderFl2.id !== lv1.id);
            if (funcOptionOneFilterLv2(lv1)) {
                listRadio.msOrderLv2[lv1.id] = '';
            }
            lv1.data.forEach((lv2) => {
                if (funcOptionOneFilterLv3(lv2)) {
                    listRadio.msOrderLv4[lv2.id] = '';
                }
                listChecked.msOrderLv3 = listChecked.msOrderLv3.filter((itemLv3) => lv2.id !== itemLv3);
                listOrderFilterLv3 = listOrderFilterLv3.filter((orderFl3) => orderFl3.id !== lv2.id);
                lv2.data.forEach((lv3) => {
                    if (funcOptionOneFilterLv3(lv3)) {
                        listRadio.msOrderLv4[lv3.id] = '';
                    }
                    listChecked.msOrderLv4 = listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
                    listOrderFilterLv4 = listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
                    contact_informations[lv3.id] = '';
                });
            });
        });
    }
    return {
        listChecked,
        listOrderFilterLv1,
        listOrderFilterLv2,
        listOrderFilterLv3,
        listOrderFilterLv4,
        listRadio,
        contact_informations
    };
}

function funcOrderLv2(itemLv2, listChecked, listOrderFilterLv2, listOrderFilterLv3, listOrderFilterLv4, listRadio, contact_informations) {
    if (listChecked.msOrderLv2.includes(itemLv2.id)) {
        listOrderFilterLv2.push(itemLv2);
        listOrderFilterLv2 = listOrderFilterLv2.sort((aLv2, bLv2) => aLv2.id - bLv2.id);
    } else {
        listOrderFilterLv2 = listOrderFilterLv2.filter((item) => item.id !== itemLv2.id);
        if (funcOptionOneFilterLv2(itemLv2)) {
            listRadio.msOrderLv2[itemLv2.id] = '';
        }
        itemLv2.data.forEach((lv2) => {
            if (funcOptionOneFilterLv3(lv2)) {
                listRadio.msOrderLv4[lv2.id] = '';
            }
            listChecked.msOrderLv3 = listChecked.msOrderLv3.filter((itemLv3) => lv2.id !== itemLv3);
            listOrderFilterLv3 = listOrderFilterLv3.filter((orderFl3) => orderFl3.id !== lv2.id);
            lv2.data.forEach((lv3) => {
                listChecked.msOrderLv4 = listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
                listOrderFilterLv4 = listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
                contact_informations[lv3.id] = '';
            });
        });
    }
    return {
        listChecked,
        listOrderFilterLv2,
        listOrderFilterLv3,
        listOrderFilterLv4,
        listRadio,
        contact_informations
    };
}

function funcOrderLv3(itemLv3, listChecked, listOrderFilterLv3, listOrderFilterLv4, listRadio, contact_informations) {
    if (listChecked.msOrderLv3.includes(itemLv3.id)) {
        listOrderFilterLv3.push(itemLv3);
        listOrderFilterLv3 = listOrderFilterLv3.sort((aLv3, bLv3) => aLv3.id - bLv3.id);
    } else {
        listOrderFilterLv3 = listOrderFilterLv3.filter((item) => item.id !== itemLv3.id);
        if (funcOptionOneFilterLv3(itemLv3)) {
            listRadio.msOrderLv4[itemLv3.id] = '';
        }
        itemLv3.data.forEach((lv3) => {
            listChecked.msOrderLv4 = listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
            listOrderFilterLv4 = listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
            contact_informations[lv3.id] = '';
        });
    }

    return {
        listChecked,
        listOrderFilterLv3,
        listOrderFilterLv4,
        listRadio,
        contact_informations
    };
}

function funcOrderLv4(itemLv4, listChecked, listOrderFilterLv4, contact_informations) {
    if (listChecked.msOrderLv4.includes(itemLv4.id)) {
        listOrderFilterLv4.push(itemLv4);
        listOrderFilterLv4 = listOrderFilterLv4.sort((aLv4, bLv4) => aLv4.id - bLv4.id);
    } else {
        listOrderFilterLv4 = listOrderFilterLv4.filter((item) => item.id !== itemLv4.id);
        contact_informations[itemLv4.id] = '';
    }

    return {
        listChecked,
        listOrderFilterLv4,
        contact_informations
    };
}

function funcOptionOneFilterLv2(itemFilterLv2) {
    return (
        itemFilterLv2.value == '電話' ||
        itemFilterLv2.value == 'メール' ||
        itemFilterLv2.value == 'はがき回収' ||
        itemFilterLv2.value == 'WEBフォーム' ||
        itemFilterLv2.value == '応募数報告' ||
        itemFilterLv2.value == 'お問い合わせ報告' ||
        itemFilterLv2.value == '現物' ||
        itemFilterLv2.value == '当選者用フォーム'
    );
}

function funcOptionOneFilterLv3(itemFilterLv3) {
    return itemFilterLv3.value == '応募数報告' || itemFilterLv3.value == 'お問い合わせ報告' || itemFilterLv3.value == '現物';
}

function funcMapDataRegisted(form, listChecked, listRadio, listOrderFilterLv1, listOrderFilterLv2, listOrderFilterLv3, listOrderFilterLv4) {
    if (form.campaign_master_order_scopes.length > 0) {
        form.campaign_master_order_scopes.forEach((itemLv1) => {
            if (!listChecked.msOrderLv1.includes(itemLv1.large_item.id)) {
                listChecked.msOrderLv1.push(itemLv1.large_item.id);
                listOrderFilterLv1 = listOrderFilterLv1.sort((aLv1, bLv1) => aLv1.id - bLv1.id);
            }
            if (itemLv1.medium_items.length > 0) {
                itemLv1.medium_items.forEach((itemLv2) => {
                    if (!listChecked.msOrderLv2.includes(itemLv2.medium_item.id)) {
                        listChecked.msOrderLv2.push(itemLv2.medium_item.id);
                        listOrderFilterLv2 = listOrderFilterLv2.sort((aLv2, bLv2) => aLv2.id - bLv2.id);
                    }
                    listRadio.msOrderLv2[itemLv1.large_item.id] = itemLv2.medium_item.id;
                    if (itemLv2.small_items.length > 0) {
                        itemLv2.small_items.forEach((itemLv3) => {
                            if (!listChecked.msOrderLv3.includes(itemLv3.small_item.id)) {
                                listChecked.msOrderLv3.push(itemLv3.small_item.id);
                                listOrderFilterLv3 = listOrderFilterLv3.sort((aLv3, bLv3) => aLv3.id - bLv3.id);
                            }
                            listRadio.msOrderLv2[itemLv2.medium_item.id] = itemLv3.small_item.id;
                            if (itemLv3.detail_items && itemLv3.detail_items.length > 0) {
                                itemLv3.detail_items.forEach((itemLv4) => {
                                    if (!listChecked.msOrderLv4.includes(itemLv4.id)) {
                                        listChecked.msOrderLv4.push(itemLv4.id);
                                        listOrderFilterLv4 = listOrderFilterLv4.sort((aLv4, bLv4) => aLv4.id - bLv4.id);
                                    }
                                    listRadio.msOrderLv4[itemLv3.small_item.id] = itemLv4.id;
                                });
                            }
                        });
                    }
                });
            }
        });
    }
}

export {
    funcSetCondition1,
    funcOrderLv1,
    funcOrderLv2,
    funcOrderLv3,
    funcOrderLv4,
    funcOptionOneFilterLv2,
    funcOptionOneFilterLv3,
    funcMapDataRegisted
};
